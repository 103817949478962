import React from "react";

const Folder = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 18 18"
    width={props.width}
    height={props.height}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.25 6H8.56125L8.208 4.9995C8.15607 4.85328 8.06012 4.72675 7.93333 4.6373C7.80655 4.54786 7.65516 4.49989 7.5 4.5H3.75C3.55109 4.5 3.36032 4.57902 3.21967 4.71967C3.07902 4.86032 3 5.05109 3 5.25V12.75C3 12.9489 3.07902 13.1397 3.21967 13.2803C3.36032 13.421 3.55109 13.5 3.75 13.5H14.25C14.4489 13.5 14.6397 13.421 14.7803 13.2803C14.921 13.1397 15 12.9489 15 12.75V6.75C15 6.55109 14.921 6.36032 14.7803 6.21967C14.6397 6.07902 14.4489 6 14.25 6ZM9.6225 4.5H14.25C14.8467 4.5 15.419 4.73705 15.841 5.15901C16.2629 5.58097 16.5 6.15326 16.5 6.75V12.75C16.5 13.3467 16.2629 13.919 15.841 14.341C15.419 14.7629 14.8467 15 14.25 15H3.75C3.15326 15 2.58097 14.7629 2.15901 14.341C1.73705 13.919 1.5 13.3467 1.5 12.75L1.5 5.25C1.5 4.65326 1.73705 4.08097 2.15901 3.65901C2.58097 3.23705 3.15326 3 3.75 3H7.5C8.4795 3 9.31275 3.62625 9.6225 4.5Z" />
  </svg>
);

export default Folder;
