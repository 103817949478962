import { dynamicMargin, dynamicPadding } from "@styles/helpers";
import { PropsWithTheme, styled, Theme } from "@styles/theme";

import { Props } from "./index";

export const bgColor =
  (state?: "hover" | "active") =>
  ({ link, color, theme }: PropsWithTheme<Props>) => {
    if (link) return "transparent";

    if (!color) return theme.colors.neutral[300];

    const variants: {
      [key: string]: string;
    } = {
      blue: state ? { hover: "400", active: "600" }[state] : "500",
      green: state ? { hover: "400", active: "600" }[state] : "500",
      red: state ? { hover: "500", active: "700" }[state] : "600",
      primary: state ? { hover: "400", active: "300" }[state] : "500",
      white: state ? { hover: "100", active: "300" }[state] : "0",
      gray: state ? { hover: "100", active: "300" }[state] : "200",
    };

    const weight = variants[color] as keyof Theme["colors"]["blue"];

    return color === "white" || color === "gray"
      ? theme.colors.neutral[weight]
      : theme.colors[color][weight];
  };

const textColor = ({
  link,
  color,
  outline,
  autofix,
  theme,
}: PropsWithTheme<Props>) =>
  outline
    ? theme.colors.neutral[700]
    : autofix
    ? theme.colors.neutral[0]
    : color == "white" || link
    ? theme.colors.blue[600]
    : color === "gray"
    ? theme.colors.primary[300]
    : theme.colors.neutral[0];

export const ButtonWrapper = styled.button<Props>`
  ${dynamicMargin};
  ${dynamicPadding};
  box-sizing: border-box;
  background-color: ${bgColor()};
  position: relative;
  display: flex;
  width: ${(p) => (p.block ? "100%" : "auto")};
  align-items: ${(p) => (p.icon ? "baseline" : "center")};
  justify-content: ${(p) => (p.block ? "center" : "auto")};
  border-radius: ${(p) => p.theme.radius};
  border: ${(p) => (p.color === "gray" || p?.outline ? "1px" : "0")} solid
    ${(p) =>
      p?.outline ? p.theme.colors.neutral[400] : p.theme.colors.neutral[500]};
  box-shadow: ${(p) => (p.link || p.noShadow ? "none" : p.theme.shadow.xs)};
  font-family: ${(p) => p.theme.fonts.main};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${textColor};
  fill: ${textColor};
  transition: 0.3s;
  svg {
    fill: inherit;
    flex: 1 0 auto;
  }
  img {
    position: absolute;
    left: ${(p) => p.theme.spacing.xg};
  }
  div ~ img {
    position: static;
  }

  span.circle {
    background-color: ${textColor};
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    width: 32px;
    height: 32px;
    margin-right: ${(p) => p.theme.spacing.xs};
    border-radius: 100%;
    box-shadow: ${(p) => p.theme.shadow.xs};
    border: ${(p) => (p.color === "gray" ? "1px" : "0")} solid
      ${(p) => p.theme.colors.neutral[500]};

    svg {
      fill: ${bgColor()};
      flex: none;
    }
  }
  :focus:not(:disabled),
  &.focus:not(:disabled) {
    box-shadow: ${(p) => p.theme.shadow.focus};
    border-color: ${(p) => p.theme.colors.blue[200]};
  }
  :disabled {
    color: ${(p) => p.theme.colors.neutral[500]};
    fill: ${(p) => p.theme.colors.neutral[500]};
    border: 0;
    background-color: ${(p) =>
      p.noBg ? "transparent" : p.theme.colors.neutral[300]};

    cursor: default !important;
  }
  :active:not(:disabled),
  &.active:not(:disabled) {
    background-color: ${bgColor("active")};
    ${(p) =>
      p.link
        ? `color: ${
            p.link ? p.theme.colors.blue[700] : p.theme.colors.blue[800]
          }`
        : ""};
    ${(p) =>
      p.link
        ? `fill: ${
            p.link ? p.theme.colors.blue[700] : p.theme.colors.blue[800]
          }`
        : ""};
  }
  :hover:not(:disabled):not(:active),
  &.hover:not(:disabled):not(.active) {
    cursor: pointer;
    background-color: ${bgColor("hover")};
    box-shadow: ${(p) => (p.link ? "none" : p.theme.shadow.hover)};
    ${(p) =>
      p.link
        ? `color: ${
            p.link ? p.theme.colors.blue[500] : p.theme.colors.blue[400]
          }`
        : ""};
    ${(p) => (p.link ? `fill: ${p.theme.colors.blue[500]}` : "")};
  }
`;
