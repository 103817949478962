import React from "react";

const Danger = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye"
  >
    <path d="M9.86732 3.07524L14.3347 10.5159C14.9767 11.5846 14.6613 12.9906 13.63 13.6559C13.2838 13.88 12.8804 13.9995 12.468 13.9999H3.53265C2.31865 13.9999 1.33398 12.9799 1.33398 11.7206C1.33398 11.2946 1.44932 10.8779 1.66598 10.5159L6.13398 3.07524C6.77532 2.00657 8.13132 1.67924 9.16265 2.34457C9.44798 2.52857 9.68932 2.77857 9.86732 3.07524ZM8.38732 3.63457C8.3057 3.58208 8.21429 3.54667 8.11861 3.53049C8.02293 3.51431 7.92496 3.5177 7.83063 3.54044C7.73629 3.56318 7.64754 3.60481 7.56974 3.66281C7.49194 3.72082 7.42671 3.79399 7.37798 3.87791L2.91065 11.3199C2.83808 11.4412 2.79983 11.5799 2.79998 11.7212C2.79998 12.1412 3.12798 12.4812 3.53332 12.4812H12.4673C12.6047 12.4812 12.7387 12.4412 12.8547 12.3666C13.0211 12.256 13.1385 12.0854 13.1823 11.8905C13.2262 11.6955 13.1931 11.4911 13.09 11.3199L8.62265 3.87791C8.56406 3.77992 8.48378 3.69668 8.38798 3.63457H8.38732ZM8.00065 11.3332C7.82384 11.3332 7.65427 11.263 7.52925 11.138C7.40422 11.013 7.33398 10.8434 7.33398 10.6666C7.33398 10.4898 7.40422 10.3202 7.52925 10.1952C7.65427 10.0701 7.82384 9.9999 8.00065 9.9999C8.17746 9.9999 8.34703 10.0701 8.47206 10.1952C8.59708 10.3202 8.66732 10.4898 8.66732 10.6666C8.66732 10.8434 8.59708 11.013 8.47206 11.138C8.34703 11.263 8.17746 11.3332 8.00065 11.3332ZM8.00065 5.33324C8.17746 5.33324 8.34703 5.40348 8.47206 5.5285C8.59708 5.65353 8.66732 5.82309 8.66732 5.99991V8.66657C8.66732 8.84338 8.59708 9.01295 8.47206 9.13798C8.34703 9.263 8.17746 9.33324 8.00065 9.33324C7.82384 9.33324 7.65427 9.263 7.52925 9.13798C7.40422 9.01295 7.33398 8.84338 7.33398 8.66657V5.99991C7.33398 5.82309 7.40422 5.65353 7.52925 5.5285C7.65427 5.40348 7.82384 5.33324 8.00065 5.33324Z" />
  </svg>
);

export default Danger;
