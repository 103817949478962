import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-9 -2 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-more-vertical-f"
  >
    <path d="M3 6a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 14a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
  </svg>
);

export default Logo;
