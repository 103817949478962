import React from "react";

const Envelope = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.73192 4.66671L7.56325 8.08004C7.68534 8.18875 7.84311 8.24881 8.00659 8.24881C8.17006 8.24881 8.32783 8.18875 8.44992 8.08004L12.2819 4.66671H3.73192ZM13.3333 5.51537L9.33725 9.07604C8.9709 9.40254 8.49731 9.58295 8.00659 9.58295C7.51586 9.58295 7.04227 9.40254 6.67592 9.07604L2.66659 5.50271V11.3334H13.3333V5.51537ZM2.66659 3.33337H13.3333C13.6869 3.33337 14.026 3.47385 14.2761 3.7239C14.5261 3.97395 14.6666 4.31309 14.6666 4.66671V11.3334C14.6666 11.687 14.5261 12.0261 14.2761 12.2762C14.026 12.5262 13.6869 12.6667 13.3333 12.6667H2.66659C2.31296 12.6667 1.97382 12.5262 1.72378 12.2762C1.47373 12.0261 1.33325 11.687 1.33325 11.3334V4.66671C1.33325 4.31309 1.47373 3.97395 1.72378 3.7239C1.97382 3.47385 2.31296 3.33337 2.66659 3.33337Z" />
  </svg>
);
export default Envelope;
