import React from "react";

const Dot = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 12 13"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6" cy="6.5" r="6" fill="#FEFCBF" />
    <circle cx="6" cy="6.5" r="3" fill="#ECC94B" />
  </svg>
);

export default Dot;
