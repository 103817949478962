import { styled } from "@styles/theme";
import { Spacing } from "@styles/helpers";
import { responsiveStyling, Responsive, formatProp } from "@styles/responsive";

type Props = { horizontal?: boolean; size?: Responsive<Spacing> };

export const Spacer = styled.div<Props>`
  display: ${(p) => (p.horizontal ? "inline-block" : "block")};
  ${(p) => {
    const formattedProp = formatProp<Spacing>(p.size, "lg");
    const sizes = formattedProp.map((s) => p.theme.spacing[s]);
    return responsiveStyling({
      width: p.horizontal ? sizes : 0,
      minWidth: p.horizontal ? sizes : 0,
      height: !p.horizontal && p.size ? sizes : 0,
    });
  }};
`;
