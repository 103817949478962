import React from "react";

const CopyCheck = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 11.3333H3.33333C2.97971 11.3333 2.64057 11.1928 2.39052 10.9427C2.14048 10.6927 2 10.3535 2 9.99992V2.66659C2 2.31296 2.14048 1.97382 2.39052 1.72378C2.64057 1.47373 2.97971 1.33325 3.33333 1.33325H8.66667C9.02029 1.33325 9.35943 1.47373 9.60948 1.72378C9.85952 1.97382 10 2.31296 10 2.66659V4.66659H12L14 6.66659V6.99992L13.3333 7.66658L12.6667 8.33325L13 8.91658L13.3333 9.49992L13.6667 10.0833L14 10.6666V13.3333C14 13.6869 13.8595 14.026 13.6095 14.2761C13.3594 14.5261 13.0203 14.6666 12.6667 14.6666H7.33333C6.97971 14.6666 6.64057 14.5261 6.39052 14.2761C6.14048 14.026 6 13.6869 6 13.3333V11.3333ZM6 9.99992V5.99992C6 5.6463 6.14048 5.30716 6.39052 5.05711C6.64057 4.80706 6.97971 4.66659 7.33333 4.66659H8.66667V2.66659H3.33333V9.99992H6ZM11.448 5.99992H7.33333V13.3333H12.6667V11.9999L13.3333 11.3333L14 10.6666L13.6667 10.0833L13.3333 9.49992L13 8.91658L12.6667 8.33325V7.21859L11.448 5.99992Z" />
    <path d="M11.0416 12.3333C10.966 12.3335 10.891 12.3184 10.8211 12.2889C10.7512 12.2595 10.6877 12.2162 10.6342 12.1617L8.18953 9.66702C8.13445 9.61282 8.0905 9.54798 8.06025 9.47627C8.03 9.40456 8.01405 9.32742 8.01333 9.24936C8.01261 9.17129 8.02714 9.09386 8.05607 9.02159C8.08499 8.94931 8.12774 8.88364 8.18181 8.8284C8.23588 8.77315 8.3002 8.72945 8.371 8.69984C8.4418 8.67023 8.51768 8.65529 8.5942 8.65592C8.67072 8.65654 8.74635 8.6727 8.81668 8.70346C8.88701 8.73423 8.95064 8.77897 9.00384 8.83508L11.041 10.9135L14.7092 7.17239C14.8172 7.06207 14.9638 7.00006 15.1167 7C15.2696 6.99995 15.4162 7.06185 15.5243 7.17209C15.6325 7.28234 15.6933 7.43189 15.6933 7.58785C15.6934 7.74382 15.6327 7.89341 15.5246 8.00374L11.4491 12.1617C11.3956 12.2162 11.332 12.2595 11.2621 12.2889C11.1922 12.3184 11.1173 12.3335 11.0416 12.3333Z" />
  </svg>
);

export default CopyCheck;
