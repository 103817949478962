import React from "react";

export const ArrowUpRight = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 19 19"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.9472 7.05697H6.5257C6.31574 7.05697 6.11437 6.97356 5.96591 6.8251C5.81744 6.67663 5.73403 6.47527 5.73403 6.2653C5.73403 6.05534 5.81744 5.85398 5.96591 5.70551C6.11437 5.55704 6.31574 5.47364 6.5257 5.47364H12.859C12.9631 5.47332 13.0662 5.49359 13.1624 5.53326C13.2586 5.57293 13.3459 5.63124 13.4195 5.70481C13.4931 5.77839 13.5514 5.86578 13.5911 5.96197C13.6307 6.05817 13.651 6.16125 13.6507 6.2653V12.5986C13.6507 12.8086 13.5673 13.01 13.4188 13.1584C13.2704 13.3069 13.069 13.3903 12.859 13.3903C12.6491 13.3903 12.4477 13.3069 12.2992 13.1584C12.1508 13.01 12.0674 12.8086 12.0674 12.5986V8.17639L6.70066 13.5431C6.55211 13.6915 6.35067 13.7749 6.14067 13.7748C5.93066 13.7747 5.72929 13.6912 5.58084 13.5427C5.4324 13.3941 5.34905 13.1927 5.34912 12.9827C5.3492 12.7727 5.43269 12.5713 5.58124 12.4229L10.9472 7.05697V7.05697Z" />
  </svg>
);

export const ArrowUp = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
  >
    <path d="M6.87499 5.71395L4.40624 8.34728C4.28836 8.46872 4.13049 8.53591 3.96661 8.5344C3.80274 8.53288 3.64598 8.46276 3.5301 8.33916C3.41422 8.21555 3.34849 8.04835 3.34707 7.87355C3.34565 7.69875 3.40864 7.53035 3.52249 7.40461L7.05812 3.63328C7.11602 3.57116 7.18488 3.52186 7.26071 3.48822C7.33654 3.45457 7.41786 3.43726 7.49999 3.43726C7.58212 3.43726 7.66344 3.45457 7.73927 3.48822C7.81511 3.52186 7.88396 3.57116 7.94187 3.63328L11.4775 7.40461C11.5885 7.53091 11.6491 7.69834 11.6465 7.87146C11.6439 8.04458 11.5782 8.20982 11.4634 8.33221C11.3485 8.4546 11.1936 8.52453 11.0313 8.52722C10.869 8.52991 10.7121 8.46514 10.5937 8.34661L8.12499 5.71328V12.1039C8.12499 12.2808 8.05914 12.4503 7.94193 12.5754C7.82472 12.7004 7.66575 12.7706 7.49999 12.7706C7.33423 12.7706 7.17526 12.7004 7.05805 12.5754C6.94084 12.4503 6.87499 12.2808 6.87499 12.1039V5.71328V5.71395Z" />
  </svg>
);

export const ArrowDown = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-5 -4.5 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
  >
    <path d="M8 11.243l3.95-3.95a1 1 0 1 1 1.414 1.414l-5.657 5.657a.997.997 0 0 1-1.414 0L.636 8.707A1 1 0 1 1 2.05 7.293L6 11.243V1.657a1 1 0 1 1 2 0v9.586z" />
  </svg>
);

export const ArrowRight = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
  >
    <path d="M10.3907 7.60469L7.75737 4.97136C7.63594 4.84563 7.56874 4.67722 7.57026 4.50243C7.57178 4.32763 7.64189 4.16042 7.76549 4.03682C7.8891 3.91321 8.05631 3.8431 8.23111 3.84158C8.4059 3.84006 8.57431 3.90726 8.70004 4.02869L12.4714 7.80003C12.5335 7.8618 12.5828 7.93524 12.6164 8.01613C12.6501 8.09701 12.6674 8.18376 12.6674 8.27136C12.6674 8.35897 12.6501 8.44571 12.6164 8.5266C12.5828 8.60748 12.5335 8.68093 12.4714 8.74269L8.70004 12.514C8.63854 12.5777 8.56498 12.6285 8.48364 12.6634C8.40231 12.6984 8.31483 12.7168 8.22631 12.7175C8.13779 12.7183 8.05 12.7014 7.96807 12.6679C7.88614 12.6344 7.81171 12.5849 7.74911 12.5223C7.68652 12.4597 7.63701 12.3853 7.60349 12.3033C7.56997 12.2214 7.5531 12.1336 7.55387 12.0451C7.55464 11.9566 7.57303 11.8691 7.60797 11.7878C7.64291 11.7064 7.6937 11.6329 7.75737 11.5714L10.3907 8.93803H4.00004C3.82323 8.93803 3.65366 8.86779 3.52864 8.74277C3.40361 8.61774 3.33337 8.44817 3.33337 8.27136C3.33337 8.09455 3.40361 7.92498 3.52864 7.79996C3.65366 7.67493 3.82323 7.60469 4.00004 7.60469H10.3907Z" />
  </svg>
);
