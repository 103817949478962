import React from "react";

const StarFilled = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 20 20"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-star"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill}
      d="M9.9998 15.3159L4.85564 18.0201L5.83814 12.2918L1.6748 8.2351L7.42647 7.39927L9.9998 2.18677L12.5723 7.39927L18.324 8.2351L14.1615 12.2918L15.1448 18.0201L9.9998 15.3159Z"
    />
  </svg>
);

export default StarFilled;
