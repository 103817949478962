import { CSSProperties } from "react";

import { styled, Theme } from "@styles/theme";
import {
  responsiveStyling,
  ResponsiveProps,
  formatProp,
  Responsive,
  formatPropSpace,
} from "@styles/responsive";
import { scrollbar } from "@styles/Scrollbar";

type FlexWrap = CSSProperties["flexWrap"];
type FlexDirection = CSSProperties["flexDirection"];
type JustifyItems = CSSProperties["justifyItems"];
type JustifyContent = CSSProperties["justifyContent"];
type AlignItems = CSSProperties["alignItems"];
type Flex = CSSProperties["flex"];

type AditionalStyles = {
  responsiveStyles?: {
    [k in keyof CSSProperties]: CSSProperties[k] | Array<CSSProperties[k]>;
  };
};

export type FlexProps = ResponsiveProps &
  AditionalStyles & {
    gridArea?: CSSProperties["gridArea"];
    round?: "xxs" | "md" | "lg" | "xl" | "full" | boolean;
    wrap?: Responsive<FlexWrap>;
    direction?: Responsive<FlexDirection>;
    justify?: Responsive<JustifyContent>;
    justifyItems?: Responsive<JustifyItems>;
    alignItems?: Responsive<AlignItems>;
    alignContent?: Responsive<CSSProperties["alignContent"]>;
    flex?: Responsive<Flex>;
    gap?: Responsive<CSSProperties["gap"]>;
    overflow?: Responsive<CSSProperties["overflow"]>;
    cursor?: CSSProperties["cursor"];
    shadow?: keyof Theme["shadow"];
  };

export type BoxProps = ResponsiveProps &
  AditionalStyles & {
    gridArea?: CSSProperties["gridArea"];
    round?: "xxs" | "md" | "lg" | "xl" | "full" | boolean;
    shadow?: keyof Theme["shadow"];
    cursor?: CSSProperties["cursor"];
    flex?: Responsive<Flex>;
    scrollableY?: Responsive<boolean>;
  };

export const Box = styled.div<BoxProps>`
  grid-area: ${(p) => p.gridArea};
  cursor: ${(p) => p.cursor || "inherit"};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadow[p.shadow] : "initial")};
  position: ${(p) => p.position || "relative"};
  border-radius: ${(p) =>
    p.round === true
      ? p.theme.spacing.xxs
      : p.round === "full"
      ? "100%"
      : p?.round
      ? p.theme.spacing[(p.round as keyof Theme["spacing"]) || "xxs"]
      : "none"};
  overflow: ${(p) => (p.scrollableY ? "auto" : "initial")};
  ${(p) =>
    responsiveStyling({
      display: formatProp<CSSProperties["display"]>(p.display, "block"),
      flex: formatProp<CSSProperties["flex"]>(p.flex, "initial"),
      gap: formatPropSpace(p.gap, "normal"),
      ...p.responsiveStyles,
    })};
`;

export const ScrollbarBox = styled(Box)`
  ${scrollbar};
`;

export const Flex = styled.div<FlexProps>`
  grid-area: ${(p) => p.gridArea};
  position: ${(p) => p.position || "relative"};
  box-shadow: ${(p) => (p.shadow ? p.theme.shadow[p.shadow] : "initial")};
  border-radius: ${(p) =>
    p.round === true
      ? p.theme.spacing.xxs
      : p.round === "full"
      ? "100%"
      : p?.round
      ? p.theme.spacing[(p.round as keyof Theme["spacing"]) || "xxs"]
      : "none"};
  ${(p) =>
    responsiveStyling({
      display: formatProp<CSSProperties["display"]>(p.display, "flex"),
      flex: formatProp<CSSProperties["flex"]>(p.flex, "initial"),
      flexDirection: formatProp<FlexDirection>(p.direction, "column"),
      justifyContent: formatProp<JustifyContent>(p.justify, "flex-start"),
      alignItems: formatProp<AlignItems>(p.alignItems, "start"),
      alignContent: formatProp<CSSProperties["alignContent"]>(
        p.alignContent,
        "initial",
      ),
      flexWrap: formatProp<FlexWrap>(p.wrap, "nowrap"),
      gap: formatPropSpace(p.gap, "normal"),
      overflow: formatProp(p.overflow, "initial"),
      cursor: p.cursor || "inherit",
      ...p.responsiveStyles,
    })}
`;

export const ScrollbarFlex = styled(Flex)`
  ${scrollbar};
`;
