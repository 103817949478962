import React from "react";

const Table = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18 18"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="bx:table">
      <path
        id="Vector"
        d="M4.20645 14H13.7935C14.4589 14 15 13.5017 15 12.8889V5.11111C15 4.49833 14.4589 4 13.7935 4H4.20645C3.54109 4 3 4.49833 3 5.11111V12.8889C3 13.5017 3.54109 14 4.20645 14ZM4.20645 12.8889V10.1111H6.61936V12.8889H4.20645ZM10.2387 6.22222V9H7.82582V6.22222H10.2387ZM6.61936 6.22222V9H4.20645V6.22222H6.61936ZM7.82582 12.8889V10.1111H10.2387V12.8889H7.82582ZM11.4452 12.8889V10.1111H13.7941V12.8889H11.4452ZM13.7935 9H11.4452V6.22222H13.7935V9Z"
      />
    </g>
  </svg>
);

export default Table;
