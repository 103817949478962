import React from "react";

const Plus = (props: { height: number; width: number; fill?: string }) => (
  <svg
    fill={props.fill}
    width={props.width}
    height={props.height}
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.69995 6.20001V3.70001C6.69995 3.5674 6.64727 3.44023 6.5535 3.34646C6.45974 3.25269 6.33256 3.20001 6.19995 3.20001C6.06734 3.20001 5.94017 3.25269 5.8464 3.34646C5.75263 3.44023 5.69995 3.5674 5.69995 3.70001V6.20001H3.19995C3.06734 6.20001 2.94017 6.25269 2.8464 6.34646C2.75263 6.44023 2.69995 6.5674 2.69995 6.70001C2.69995 6.83262 2.75263 6.9598 2.8464 7.05357C2.94017 7.14733 3.06734 7.20001 3.19995 7.20001H5.69995V9.70001C5.69995 9.83262 5.75263 9.9598 5.8464 10.0536C5.94017 10.1473 6.06734 10.2 6.19995 10.2C6.33256 10.2 6.45974 10.1473 6.5535 10.0536C6.64727 9.9598 6.69995 9.83262 6.69995 9.70001V7.20001H9.19995C9.33256 7.20001 9.45974 7.14733 9.5535 7.05357C9.64727 6.9598 9.69995 6.83262 9.69995 6.70001C9.69995 6.5674 9.64727 6.44023 9.5535 6.34646C9.45974 6.25269 9.33256 6.20001 9.19995 6.20001H6.69995Z" />
  </svg>
);

export default Plus;
