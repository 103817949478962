import React from "react";

const Share = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 17"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-tag"
  >
    <path d="M3.66671 10.4999C4.27337 10.4999 4.82204 10.2613 5.23804 9.87992L9.41137 12.2646C9.36195 12.4502 9.33576 12.6412 9.33337 12.8333C9.33337 14.1199 10.38 15.1666 11.6667 15.1666C12.9534 15.1666 14 14.1199 14 12.8333C14 11.5466 12.9534 10.4999 11.6667 10.4999C11.06 10.4999 10.5114 10.7386 10.0954 11.1199L5.92204 8.73525C5.96404 8.57059 5.99071 8.40059 5.99404 8.22325L10.0947 5.87992C10.5114 6.26125 11.06 6.49992 11.6667 6.49992C12.9534 6.49992 14 5.45325 14 4.16659C14 2.87992 12.9534 1.83325 11.6667 1.83325C10.38 1.83325 9.33337 2.87992 9.33337 4.16659C9.33337 4.36392 9.36537 4.55192 9.41137 4.73525L5.62204 6.90125C5.4112 6.57414 5.1218 6.30503 4.78026 6.11847C4.43871 5.93192 4.05588 5.83386 3.66671 5.83325C2.38004 5.83325 1.33337 6.87992 1.33337 8.16659C1.33337 9.45325 2.38004 10.4999 3.66671 10.4999ZM11.6667 11.8333C12.218 11.8333 12.6667 12.2819 12.6667 12.8333C12.6667 13.3846 12.218 13.8333 11.6667 13.8333C11.1154 13.8333 10.6667 13.3846 10.6667 12.8333C10.6667 12.2819 11.1154 11.8333 11.6667 11.8333ZM11.6667 3.16659C12.218 3.16659 12.6667 3.61525 12.6667 4.16659C12.6667 4.71792 12.218 5.16659 11.6667 5.16659C11.1154 5.16659 10.6667 4.71792 10.6667 4.16659C10.6667 3.61525 11.1154 3.16659 11.6667 3.16659ZM3.66671 7.16659C4.21804 7.16659 4.66671 7.61525 4.66671 8.16659C4.66671 8.71792 4.21804 9.16659 3.66671 9.16659C3.11537 9.16659 2.66671 8.71792 2.66671 8.16659C2.66671 7.61525 3.11537 7.16659 3.66671 7.16659Z" />
  </svg>
);

export default Share;
