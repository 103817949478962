import React from "react";

const HourGlass = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    fill={props.fill}
  >
    <path d="M6.75 3V6C6.75 6.59674 6.98705 7.16903 7.40901 7.59099C7.83097 8.01295 8.40326 8.25 9 8.25C9.59674 8.25 10.169 8.01295 10.591 7.59099C11.0129 7.16903 11.25 6.59674 11.25 6V3H6.75ZM12.75 15C12.9489 15 13.1397 15.079 13.2803 15.2197C13.421 15.3603 13.5 15.5511 13.5 15.75C13.5 15.9489 13.421 16.1397 13.2803 16.2803C13.1397 16.421 12.9489 16.5 12.75 16.5H5.25C5.05109 16.5 4.86032 16.421 4.71967 16.2803C4.57902 16.1397 4.5 15.9489 4.5 15.75C4.5 15.5511 4.57902 15.3603 4.71967 15.2197C4.86032 15.079 5.05109 15 5.25 15V12C5.24942 11.4177 5.3847 10.8434 5.6451 10.3226C5.9055 9.80175 6.28383 9.3489 6.75 9C6.28392 8.65102 5.90565 8.19815 5.64526 7.67737C5.38487 7.15659 5.24953 6.58225 5.25 6V3C5.05109 3 4.86032 2.92098 4.71967 2.78033C4.57902 2.63968 4.5 2.44891 4.5 2.25C4.5 2.05109 4.57902 1.86032 4.71967 1.71967C4.86032 1.57902 5.05109 1.5 5.25 1.5H12.75C12.9489 1.5 13.1397 1.57902 13.2803 1.71967C13.421 1.86032 13.5 2.05109 13.5 2.25C13.5 2.44891 13.421 2.63968 13.2803 2.78033C13.1397 2.92098 12.9489 3 12.75 3V6C12.7505 6.58225 12.6151 7.15659 12.3547 7.67737C12.0943 8.19815 11.7161 8.65102 11.25 9C11.7162 9.3489 12.0945 9.80175 12.3549 10.3226C12.6153 10.8434 12.7506 11.4177 12.75 12V15ZM11.25 15V12C11.25 11.4033 11.0129 10.831 10.591 10.409C10.169 9.98705 9.59674 9.75 9 9.75C8.40326 9.75 7.83097 9.98705 7.40901 10.409C6.98705 10.831 6.75 11.4033 6.75 12V15H11.25Z" />
  </svg>
);

export default HourGlass;
