import React from "react";

const Download = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye"
  >
    <path d="M8.66668 7.76059L9.41401 7.01392C9.53966 6.89239 9.70801 6.82507 9.88281 6.82646C10.0576 6.82786 10.2249 6.89785 10.3486 7.02137C10.4723 7.14489 10.5425 7.31205 10.5441 7.48685C10.5458 7.66165 10.4787 7.8301 10.3573 7.95592L8.47134 9.84192C8.40957 9.90404 8.33613 9.95334 8.25525 9.98698C8.17436 10.0206 8.08762 10.0379 8.00001 10.0379C7.91241 10.0379 7.82566 10.0206 7.74478 9.98698C7.66389 9.95334 7.59045 9.90404 7.52868 9.84192L5.64268 7.95592C5.58078 7.89393 5.5317 7.82037 5.49823 7.73941C5.46477 7.65846 5.44758 7.57171 5.44764 7.48411C5.44776 7.3072 5.51816 7.13759 5.64334 7.01259C5.76853 6.88758 5.93824 6.81742 6.11515 6.81755C6.29206 6.81767 6.46167 6.88807 6.58668 7.01325L7.33334 7.76059V3.99992C7.33334 3.82311 7.40358 3.65354 7.52861 3.52851C7.65363 3.40349 7.8232 3.33325 8.00001 3.33325C8.17682 3.33325 8.34639 3.40349 8.47141 3.52851C8.59644 3.65354 8.66668 3.82311 8.66668 3.99992V7.76059ZM4.00001 11.3333H12C12.1768 11.3333 12.3464 11.4035 12.4714 11.5285C12.5964 11.6535 12.6667 11.8231 12.6667 11.9999C12.6667 12.1767 12.5964 12.3463 12.4714 12.4713C12.3464 12.5963 12.1768 12.6666 12 12.6666H4.00001C3.8232 12.6666 3.65363 12.5963 3.52861 12.4713C3.40358 12.3463 3.33334 12.1767 3.33334 11.9999C3.33334 11.8231 3.40358 11.6535 3.52861 11.5285C3.65363 11.4035 3.8232 11.3333 4.00001 11.3333Z" />
  </svg>
);

export default Download;
