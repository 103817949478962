import React from "react";

const Inbox = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="inbox 1">
      <path
        id="Vector"
        d="M2.66634 7.13434V11.333H13.333V7.13434L11.359 4.66634H4.63967L2.66634 7.13434ZM1.33301 6.66634L3.99967 3.33301H11.9997L14.6663 6.66634V11.333C14.6663 11.6866 14.5259 12.0258 14.2758 12.2758C14.0258 12.5259 13.6866 12.6663 13.333 12.6663H2.66634C2.31272 12.6663 1.97358 12.5259 1.72353 12.2758C1.47348 12.0258 1.33301 11.6866 1.33301 11.333V6.66634ZM10.5823 7.99967C10.434 8.57183 10.1 9.07855 9.63252 9.44029C9.16507 9.80203 8.59074 9.9983 7.99967 9.9983C7.40861 9.9983 6.83428 9.80203 6.36683 9.44029C5.89939 9.07855 5.5653 8.57183 5.41701 7.99967H2.66634V6.66634H13.333V7.99967H10.5823ZM9.15434 7.99967H6.84501C6.96204 8.20235 7.13035 8.37065 7.33304 8.48767C7.53572 8.60468 7.76564 8.66628 7.99967 8.66628C8.23371 8.66628 8.46363 8.60468 8.66631 8.48767C8.869 8.37065 9.03731 8.20235 9.15434 7.99967Z"
      />
    </g>
  </svg>
);

export default Inbox;
