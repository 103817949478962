import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    fill={props.fill}
    height={props.height}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66671 10C6.66671 10.9205 5.92052 11.6667 5.00004 11.6667C4.07957 11.6667 3.33337 10.9205 3.33337 10C3.33337 9.07952 4.07957 8.33333 5.00004 8.33333C5.92052 8.33333 6.66671 9.07952 6.66671 10ZM11.6667 10C11.6667 10.9205 10.9205 11.6667 10 11.6667C9.07957 11.6667 8.33337 10.9205 8.33337 10C8.33337 9.07952 9.07957 8.33333 10 8.33333C10.9205 8.33333 11.6667 9.07952 11.6667 10ZM15 11.6667C15.9205 11.6667 16.6667 10.9205 16.6667 10C16.6667 9.07952 15.9205 8.33333 15 8.33333C14.0796 8.33333 13.3334 9.07952 13.3334 10C13.3334 10.9205 14.0796 11.6667 15 11.6667Z"
    />
  </svg>
);

export default Logo;
