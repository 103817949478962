import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 10"
    fill={props.fill}
    width={props.width}
    height={props.height}
    preserveAspectRatio="xMinYMin"
    className="jam jam-chevron-up"
  >
    <path d="M5.02962 4.30311L2.96712 6.36561C2.88853 6.44151 2.78328 6.4835 2.67403 6.48256C2.56478 6.48161 2.46028 6.43779 2.38303 6.36053C2.30577 6.28328 2.26195 6.17877 2.261 6.06952C2.26005 5.96028 2.30205 5.85503 2.37795 5.77644L4.73503 3.41936C4.81317 3.34124 4.91913 3.29736 5.02962 3.29736C5.1401 3.29736 5.24606 3.34124 5.3242 3.41936L7.68128 5.77644C7.75718 5.85503 7.79918 5.96028 7.79823 6.06952C7.79728 6.17877 7.75346 6.28328 7.67621 6.36053C7.59896 6.43779 7.49445 6.48161 7.3852 6.48256C7.27595 6.4835 7.1707 6.44151 7.09212 6.36561L5.02962 4.30311Z" />
  </svg>
);

export default Logo;
