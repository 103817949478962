import React from "react";

interface Props {
  fill: string;
  width: number;
  height: number;
}

const Bell = ({ fill = "white", width = 16, height = 18 }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8883 10.485L11.3333 9.98829V5.66663C11.3333 4.78257 10.9821 3.93472 10.357 3.3096C9.7319 2.68448 8.88406 2.33329 8 2.33329C7.11595 2.33329 6.2681 2.68448 5.64298 3.3096C5.01786 3.93472 4.66667 4.78257 4.66667 5.66663V9.98829L4.11167 10.485C3.30629 11.2037 2.71656 12.1321 2.40834 13.1666H13.5917C13.2834 12.1321 12.6937 11.2037 11.8883 10.485ZM10.5 14.8333C10.5 15.4963 10.2366 16.1322 9.76777 16.6011C9.29893 17.0699 8.66304 17.3333 8 17.3333C7.33696 17.3333 6.70108 17.0699 6.23224 16.6011C5.7634 16.1322 5.5 15.4963 5.5 14.8333H0.500004C0.498871 13.7779 0.721036 12.7342 1.15191 11.7708C1.58277 10.8074 2.2126 9.94602 3 9.24329V5.66663C3 4.34054 3.52679 3.06877 4.46447 2.13109C5.40215 1.19341 6.67392 0.666626 8 0.666626C9.32609 0.666626 10.5979 1.19341 11.5355 2.13109C12.4732 3.06877 13 4.34054 13 5.66663V9.24329C13.7874 9.94602 14.4172 10.8074 14.8481 11.7708C15.279 12.7342 15.5011 13.7779 15.5 14.8333H10.5ZM8 15.6666C8.22102 15.6666 8.43298 15.5788 8.58926 15.4225C8.74554 15.2663 8.83334 15.0543 8.83334 14.8333H7.16667C7.16667 15.0543 7.25447 15.2663 7.41075 15.4225C7.56703 15.5788 7.77899 15.6666 8 15.6666Z"
      fill={fill}
    />
  </svg>
);

export default Bell;
