import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const Calendar: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 -3 24 24"
    fill={props.fill}
    width={props.width}
    height={props.height}
    preserveAspectRatio="xMinYMin"
    className="icon__icon"
  >
    <path d="M18 7V5a1 1 0 0 0-1-1h-1v1a1 1 0 0 1-2 0V4H6v1a1 1 0 1 1-2 0V4H3a1 1 0 0 0-1 1v2h16zm0 2H2v6a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V9zm-2-7h1a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V5a3 3 0 0 1 3-3h1V1a1 1 0 1 1 2 0v1h8V1a1 1 0 0 1 2 0v1z" />
  </svg>
);

export default Calendar;
