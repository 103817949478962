import React from "react";

const Flag = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 1.5H9C9.26522 1.5 9.51957 1.60536 9.70711 1.79289C9.89464 1.98043 10 2.23478 10 2.5V6C10 6.26522 9.89464 6.51957 9.70711 6.70711C9.51957 6.89464 9.26522 7 9 7H3V10.5C3 10.6326 2.94732 10.7598 2.85355 10.8536C2.75979 10.9473 2.63261 11 2.5 11C2.36739 11 2.24021 10.9473 2.14645 10.8536C2.05268 10.7598 2 10.6326 2 10.5V1.5C2 1.36739 2.05268 1.24021 2.14645 1.14645C2.24021 1.05268 2.36739 1 2.5 1C2.63261 1 2.75979 1.05268 2.85355 1.14645C2.94732 1.24021 3 1.36739 3 1.5ZM3 6H9V2.5H3V6Z" />
  </svg>
);

export default Flag;
