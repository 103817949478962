import React from "react";

const Accenture = () => (
  <svg
    version="1.1"
    id="Greater_Than"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 328.0399 360"
    enableBackground="new 0 0 328.0399 360"
    xmlSpace="preserve"
    height={16}
  >
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1="0"
      y1="180"
      x2="328.0399"
      y2="180"
    >
      <stop offset="0" style={{ stopColor: "#460073" }} />
      <stop offset="0.1742" style={{ stopColor: "#520085" }} />
      <stop offset="0.5257" style={{ stopColor: "#7100B5" }} />
      <stop offset="1" style={{ stopColor: "#A100FF" }} />
    </linearGradient>
    <polygon
      fill="url(#SVGID_1_)"
      points="0,360 328.0399,226.9993 328.0399,133.0008 0,0 0,93.9987 212.1184,180 0,266.0013 "
    />
  </svg>
);

export default Accenture;
