import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-8 -5 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-chevron-right"
  >
    <path d="M5.314 7.071l-4.95-4.95A1 1 0 0 1 1.778.707l5.657 5.657a1 1 0 0 1 0 1.414l-5.657 5.657a1 1 0 0 1-1.414-1.414l4.95-4.95z" />
  </svg>
);

export default Logo;
