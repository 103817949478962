import React from "react";

const Code = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.3241 2.70926C10.6701 2.8422 10.8485 3.24606 10.7226 3.61131L7.53037 12.87C7.40444 13.2353 7.02188 13.4236 6.67589 13.2907C6.32991 13.1577 6.15151 12.7539 6.27744 12.3886L9.46963 3.12989C9.59556 2.76464 9.97812 2.57632 10.3241 2.70926ZM3.44281 8.17072L5.79983 10.659C6.06018 10.9338 6.06018 11.3794 5.79983 11.6543C5.53948 11.9291 5.11737 11.9291 4.85702 11.6543L2.02859 8.66837C1.76824 8.39353 1.76824 7.94792 2.02859 7.67308L4.85702 4.68719C5.11737 4.41235 5.53948 4.41235 5.79983 4.68719C6.06018 4.96203 6.06018 5.40764 5.79983 5.68249L3.44281 8.17072ZM13.5572 8.17072L11.2002 5.68249C10.9398 5.40764 10.9398 4.96203 11.2002 4.68719C11.4605 4.41235 11.8826 4.41235 12.143 4.68719L14.9714 7.67308C15.2318 7.94792 15.2318 8.39353 14.9714 8.66837L12.143 11.6543C11.8826 11.9291 11.4605 11.9291 11.2002 11.6543C10.9398 11.3794 10.9398 10.9338 11.2002 10.659L13.5572 8.17072Z" />
  </svg>
);

export default Code;
