import React from "react";

const Linkedin = (props: { width: number; height: number }) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 24 24"
    fill="#303C46"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.98292 7.19729C6.19132 7.19729 7.17092 6.21769 7.17092 5.00929C7.17092 3.80089 6.19132 2.82129 4.98292 2.82129C3.77452 2.82129 2.79492 3.80089 2.79492 5.00929C2.79492 6.21769 3.77452 7.19729 4.98292 7.19729Z" />
    <path d="M9.23697 8.85518V20.9942H13.006V14.9912C13.006 13.4072 13.304 11.8732 15.268 11.8732C17.205 11.8732 17.229 13.6842 17.229 15.0912V20.9952H21V14.3382C21 11.0682 20.296 8.55518 16.474 8.55518C14.639 8.55518 13.409 9.56218 12.906 10.5152H12.855V8.85518H9.23697ZM3.09497 8.85518H6.86997V20.9942H3.09497V8.85518Z" />
  </svg>
);

export default Linkedin;
