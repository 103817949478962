import React from "react";
import { keyframes } from "@emotion/core";

import {
  MarginProps,
  PaddingProps,
  dynamicMargin,
  dynamicPadding,
  getColorDotWeight,
} from "@styles/helpers";
import { ColorDotWeight, styled } from "@styles/theme";

export type Props = MarginProps &
  PaddingProps & {
    color?: ColorDotWeight;
    size?: number;
  };

const spin = keyframes`
  to { transform: rotate(360deg); }
`;

const Container = styled.div<Props>`
  ${dynamicMargin}
  ${dynamicPadding}

  width: ${(p) => `${p.size}px`};
  min-width: ${(p) => `${p.size}px`};
  height: ${(p) => `${p.size}px`};
  min-height: ${(p) => `${p.size}px`};
  border-radius: 50%;
  overflow: hidden;

  svg {
    display: block;
    fill: ${(p) => getColorDotWeight(p.color || "blue.500")};
    animation: ${spin} 1s ease-in-out infinite;
  }
`;

export const Spinner: React.FC<Props> = ({ size = 12, ...props }) => (
  <Container size={size} {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 17"
    >
      <path d="M7.99967 15.1673C11.6137 15.1673 14.6663 12.1147 14.6663 8.50065H13.333C13.333 11.392 10.891 13.834 7.99967 13.834C5.10834 13.834 2.66634 11.392 2.66634 8.50065C2.66634 5.60998 5.10834 3.16732 7.99967 3.16732V1.83398C4.38567 1.83398 1.33301 4.88732 1.33301 8.50065C1.33301 12.1147 4.38567 15.1673 7.99967 15.1673Z" />
    </svg>
  </Container>
);
