import React from "react";

const IdCard = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 16 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.66732 3.33301H13.334C13.6876 3.33301 14.0267 3.47348 14.2768 3.72353C14.5268 3.97358 14.6673 4.31272 14.6673 4.66634V11.333C14.6673 11.6866 14.5268 12.0258 14.2768 12.2758C14.0267 12.5259 13.6876 12.6663 13.334 12.6663H2.66732C2.3137 12.6663 1.97456 12.5259 1.72451 12.2758C1.47446 12.0258 1.33398 11.6866 1.33398 11.333V4.66634C1.33398 4.31272 1.47446 3.97358 1.72451 3.72353C1.97456 3.47348 2.3137 3.33301 2.66732 3.33301ZM2.66732 4.66634V11.333H13.334V4.66634H2.66732ZM8.66732 5.99967H12.0007C12.1775 5.99967 12.347 6.06991 12.4721 6.19494C12.5971 6.31996 12.6673 6.48953 12.6673 6.66634C12.6673 6.84315 12.5971 7.01272 12.4721 7.13775C12.347 7.26277 12.1775 7.33301 12.0007 7.33301H8.66732C8.49051 7.33301 8.32094 7.26277 8.19591 7.13775C8.07089 7.01272 8.00065 6.84315 8.00065 6.66634C8.00065 6.48953 8.07089 6.31996 8.19591 6.19494C8.32094 6.06991 8.49051 5.99967 8.66732 5.99967ZM8.66732 7.99967H12.0007C12.1775 7.99967 12.347 8.06991 12.4721 8.19494C12.5971 8.31996 12.6673 8.48953 12.6673 8.66634C12.6673 8.84315 12.5971 9.01272 12.4721 9.13775C12.347 9.26277 12.1775 9.33301 12.0007 9.33301H8.66732C8.49051 9.33301 8.32094 9.26277 8.19591 9.13775C8.07089 9.01272 8.00065 8.84315 8.00065 8.66634C8.00065 8.48953 8.07089 8.31996 8.19591 8.19494C8.32094 8.06991 8.49051 7.99967 8.66732 7.99967ZM4.00065 5.99967H6.00065C6.17746 5.99967 6.34703 6.06991 6.47205 6.19494C6.59708 6.31996 6.66732 6.48953 6.66732 6.66634V8.66634C6.66732 8.84315 6.59708 9.01272 6.47205 9.13775C6.34703 9.26277 6.17746 9.33301 6.00065 9.33301H4.00065C3.82384 9.33301 3.65427 9.26277 3.52925 9.13775C3.40422 9.01272 3.33398 8.84315 3.33398 8.66634V6.66634C3.33398 6.48953 3.40422 6.31996 3.52925 6.19494C3.65427 6.06991 3.82384 5.99967 4.00065 5.99967Z" />
  </svg>
);

export default IdCard;
