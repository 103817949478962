import React, { ButtonHTMLAttributes, CSSProperties } from "react";

import Icon, { IconName } from "@icons/Icon";
import { MarginProps, PaddingProps } from "@styles/helpers";

import { Spacer } from "../Spacer";
import { Flex } from "../ResponsiveBox";
import Link from "../Link";
import { Spinner } from "../Spinner";
import { ButtonWrapper } from "./styles";

export type Colors = "blue" | "green" | "red" | "primary" | "white" | "gray";

export type Props = MarginProps &
  PaddingProps & {
    block?: boolean;
    color?: Colors;
    link?: boolean;
    small?: boolean;
    outline?: boolean;
    autofix?: boolean;
    inline?: boolean;
    centralize?: boolean;
    noShadow?: boolean;
    noPadding?: boolean;
    disabled?: boolean;
    loading?: boolean;
    loadingLeft?: boolean;
    loadingVariant?: "dark" | "light";
    noBg?: boolean;
    style?: CSSProperties;
    icon?: {
      name: IconName;
      fill?: string;
      size?: number;
      right?: boolean;
      circle?: boolean;
    };

    href?: string;
    as?: string;
    target?: string;
    onPress?: (e: React.MouseEvent) => void;
    type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
    className?: string;
  };

const Button: React.FC<Props> = ({
  children,
  color = "primary",
  small,
  inline,
  centralize,
  noPadding,
  disabled,
  loading,
  loadingLeft,
  loadingVariant,
  icon,
  href,
  target = "_self",
  onPress,
  ...props
}) => {
  const handlePress = (e: React.MouseEvent) => {
    if (onPress) return onPress(e);
  };

  const IconContainer: React.FC = ({ children }) => {
    if (icon?.circle) {
      return <span className="circle">{children}</span>;
    }

    return <>{children}</>;
  };

  const ButtonContainer: React.FC = ({ children }) => {
    if (!href) {
      return (
        <Flex
          alignItems={centralize ? "center" : undefined}
          {...(inline ? { style: { display: "inline-block" } } : null)}
          {...(props.block ? { width: "100%" } : null)}
        >
          {children}
        </Flex>
      );
    }

    return (
      <Flex
        alignItems={centralize ? "center" : undefined}
        {...(inline ? { style: { display: "inline-block" } } : null)}
        {...(props.block ? { width: "100%" } : null)}
      >
        <Link
          href={href}
          as={props.as}
          target={target}
          style={{ ...(props.block ? { width: "100%" } : null) }}
        >
          {children}
        </Link>
      </Flex>
    );
  };

  return (
    <ButtonContainer>
      <ButtonWrapper
        onClick={handlePress}
        disabled={disabled || loading}
        color={color}
        pY={noPadding ? "none" : small ? "xs" : "sm"}
        pX={noPadding ? "none" : small ? "md" : "xg"}
        {...props}
      >
        {!(loading && loadingLeft) && icon && !icon.right && (
          <>
            <IconContainer>
              <Icon size={icon.size} name={icon.name} fill={icon.fill} />
            </IconContainer>
            <Spacer size={props.block ? "none" : "xs"} horizontal />
          </>
        )}

        <Flex
          width="100%"
          pL={icon?.right ? "md" : "none"}
          alignItems="center"
          direction="row"
          justify="center"
          cursor={disabled ? "default" : "pointer"}
        >
          {loading && !loadingLeft && (
            <Spinner
              color={
                disabled
                  ? "neutral.500"
                  : loadingVariant === "dark"
                  ? "neutral.800"
                  : "neutral.0"
              }
              mR="xxs"
            />
          )}

          {children}

          {loading && loadingLeft && (
            <Spinner
              color={
                disabled
                  ? "neutral.500"
                  : loadingVariant === "dark"
                  ? "neutral.800"
                  : "neutral.0"
              }
              mL="xxs"
            />
          )}
        </Flex>

        {icon && icon.right && (
          <>
            <Spacer size={props.block ? "none" : "xs"} horizontal />
            <IconContainer>
              <Icon size={icon.size} name={icon.name} fill={icon.fill} />
            </IconContainer>
          </>
        )}
      </ButtonWrapper>
    </ButtonContainer>
  );
};

export default Button;
