import React, { HTMLProps } from "react";
import invariant from "ts-invariant";

import { theme } from "../styles/theme";

import AccentureLogo from "./accenture";
import Activity from "./activity";
import { AlertIcon } from "./alert";
import AlertTrial from "./alert-trial";
import ArrowCircleLeft from "./arrow-circle-left";
import ArrowCircleMinus from "./arrow-circle-minus";
import { ArrowRight, ArrowUp, ArrowUpRight, ArrowDown } from "./arrow";
import BarChart from "./bar-chart";
import Bell from "./bell";
import bug from "./bug";
import Calendar from "./calendar";
import Check from "./check";
import ChevronCircleLeft from "./chevron-circle-left";
import ChevronCircleRight from "./chevron-circle-right";
import ChevronDown from "./chevron-down";
import ChevronLeft from "./chevron-left";
import ChevronRight from "./chevron-right";
import ChevronUp from "./chevron-up";
import ChevronsDown from "./chevrons-down";
import Cloud from "./cloud";
import close from "./close";
import CircleInfo from "./circle-info";
import clock from "./clock";
import Code from "./code";
import cog from "./cog";
import coin from "./coin";
import Copy from "./copy";
import CopyCheck from "./copy-check";
import CreditCard from "./credit-card";
import Danger from "./danger";
import Document from "./document";
import download from "./download";
import Envelope from "./envelope";
import eye from "./eye";
import EyeClose from "./eye-close";
import EyeCloseFilled from "./eye-close-filled";
import Filter from "./filter";
import folder from "./folder";
import GoogleLogo from "./google";
import help from "./help";
import idCard from "./id-card";
import info from "./info";
import Key from "./key";
import leaf from "./leaf";
import Link from "./link";
import LinkedinLogo from "./linkedin";
import Logout from "./log-out";
import minus from "./minus";
import MoreHorizontalF from "./more-horizontal-f";
import MoreVerticalF from "./more-vertical-f";
import padlock from "./padlock";
import pencil from "./pencil";
import PieChartAlt from "./pie-chart-alt";
import play from "./play";
import plus from "./plus";
import plusCircle from "./plus-circle";
import QuestionMark from "./question-mark";
import Refresh from "./refresh";
import Repeat from "./repeat";
import Rocket from "./rocket";
import Search from "./search";
import SettingsAlt from "./settings-alt";
import SortAscending from "./sort-ascending";
import SortDescending from "./sort-descending";
import Share from "./share";
import Star from "./star";
import StarFilled from "./star-filled";
import Tag from "./tag";
import TaskList from "./task-list";
import trash from "./trash";
import update from "./update";
import UserCircle from "./user-cicle";
import User from "./user";
import Users from "./users";
import HourGlass from "./hour-glass";
import Percentage from "./percentage";
import LowSeverity from "./low-severity";
import MediumSeverity from "./medium-severity";
import HighSeverity from "./high-severity";
import Flag from "./flag";
import FlagFilled from "./flag-f";
import Table from "./table";
import Inbox from "./inbox";
import Dot from "./Dot";
import Archive from "./archive";
import LowProbability from "./low-probability";
import MediumProbability from "./medium-probability";
import HighProbability from "./high-probability";
import Magic from "./magic";
import MessagesAlt from "./messages-alt";

export const Icons = {
  accenture: AccentureLogo,
  activity: Activity,
  "alert-trial": AlertTrial,
  alert: AlertIcon,
  "arrow-circle-left": ArrowCircleLeft,
  "arrow-circle-minus": ArrowCircleMinus,
  "arrow-down": ArrowDown,
  "arrow-right": ArrowRight,
  "arrow-up-right": ArrowUpRight,
  "arrow-up": ArrowUp,
  "bar-chart": BarChart,
  bell: Bell,
  bug,
  calendar: Calendar,
  check: Check,
  "chevron-circle-left": ChevronCircleLeft,
  "chevron-circle-right": ChevronCircleRight,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "chevrons-down": ChevronsDown,
  "circle-info": CircleInfo,
  clock,
  close,
  cloud: Cloud,
  code: Code,
  cog,
  coin,
  "copy-check": CopyCheck,
  copy: Copy,
  "credit-card": CreditCard,
  danger: Danger,
  document: Document,
  download,
  envelope: Envelope,
  "eye-close-filled": EyeCloseFilled,
  "eye-close": EyeClose,
  eye,
  filter: Filter,
  folder,
  google: GoogleLogo,
  "hour-glass": HourGlass,
  help,
  "id-card": idCard,
  info,
  key: Key,
  leaf,
  link: Link,
  linkedin: LinkedinLogo,
  "log-out": Logout,
  minus,
  "more-horizontal-f": MoreHorizontalF,
  "more-vertical-f": MoreVerticalF,
  padlock,
  pencil,
  percentage: Percentage,
  "pie-chart-alt": PieChartAlt,
  play,
  plus,
  "plus-circle": plusCircle,
  "question-mark": QuestionMark,
  refresh: Refresh,
  repeat: Repeat,
  rocket: Rocket,
  search: Search,
  "settings-alt": SettingsAlt,
  "sort-ascending": SortAscending,
  "sort-descending": SortDescending,
  share: Share,
  "star-filled": StarFilled,
  star: Star,
  tag: Tag,
  "task-list": TaskList,
  trash,
  update,
  "user-circle": UserCircle,
  user: User,
  users: Users,
  "low-severity": LowSeverity,
  "medium-severity": MediumSeverity,
  "high-severity": HighSeverity,
  flag: Flag,
  "flag-f": FlagFilled,
  table: Table,
  inbox: Inbox,
  dot: Dot,
  archive: Archive,
  "low-probability": LowProbability,
  "medium-probability": MediumProbability,
  "high-probability": HighProbability,
  magic: Magic,
  "message-alt": MessagesAlt,
};

export type IconName = keyof typeof Icons;

export interface Props {
  name: IconName;
  width?: number;
  height?: number;
  size?: number;
  fill?: string;
  styles?: React.CSSProperties;
}

const Icon: React.FC<Props & HTMLProps<HTMLOrSVGElement>> = ({
  name,
  size = 16,
  width,
  height,
  fill = "blue.100",
  ...props
}) => {
  const Component = Icons[name];

  const [fillColorName, fillColorWeight] = fill.split(".");
  // @ts-ignore
  const fillColor = theme.colors[fillColorName];
  invariant(fillColor, `Fill color does not exist on the theme`);
  invariant(Component, "Icon component being called with an unknown icon name");

  return (
    <Component
      width={width || size}
      height={height || size}
      fill={fillColor[fillColorWeight]}
      {...props}
    />
  );
};

export default Icon;
