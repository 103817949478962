import React from "react";

const Activity = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.0775 9.16599L5.3335 3.29865L4.5895 9.16532H2.00016C1.63216 9.16532 1.3335 8.87399 1.3335 8.51466C1.33561 8.33984 1.40699 8.17301 1.53198 8.05077C1.65696 7.92853 1.82535 7.86088 2.00016 7.86265H3.41083L4.01083 3.13865C4.10016 2.42399 4.76683 1.91732 5.49683 2.00532C6.1035 2.07865 6.58083 2.54532 6.65616 3.13865L7.2555 7.86265H8.5715L9.3335 12.6933L10.0948 7.86332H14.0002C14.3682 7.86332 14.6668 8.15532 14.6668 8.51532C14.6668 8.87532 14.3682 9.16665 14.0002 9.16665H11.2388L10.6515 12.8913C10.6056 13.1678 10.4728 13.4224 10.2723 13.6183C10.0719 13.8142 9.81427 13.9411 9.53683 13.9807C8.80883 14.0907 8.12816 13.6027 8.0155 12.8913L7.42883 9.16665H6.07683L6.0775 9.16599Z" />
  </svg>
);

export default Activity;
