import React from "react";

const Cloud = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 16 12"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-cloud"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.24985 11.2499C3.70744 11.2496 3.17154 11.1317 2.67914 10.9042C2.18673 10.6767 1.74954 10.345 1.39773 9.9322C1.04592 9.51935 0.787851 9.03509 0.64134 8.51284C0.494829 7.99058 0.463359 7.44276 0.549101 6.90716C0.634843 6.37156 0.835755 5.86094 1.13798 5.41052C1.4402 4.9601 1.83654 4.5806 2.29965 4.29821C2.76277 4.01582 3.28163 3.83726 3.82044 3.77484C4.35926 3.71243 4.9052 3.76764 5.4206 3.93668C5.89653 2.8249 6.7416 1.91146 7.81306 1.35064C8.88453 0.78982 10.1167 0.615994 11.3015 0.858525C12.4863 1.10106 13.5511 1.74508 14.316 2.68181C15.0809 3.61854 15.4991 4.79057 15.4999 5.99993C15.4999 7.39232 14.9467 8.72768 13.9622 9.71224C12.9776 10.6968 11.6422 11.2499 10.2499 11.2499H4.24985ZM4.24985 9.74993H10.2499C10.9271 9.74962 11.5916 9.56592 12.1728 9.21833C12.754 8.87075 13.2303 8.37225 13.551 7.77577C13.8717 7.1793 14.0249 6.50711 13.9943 5.83057C13.9637 5.15403 13.7505 4.49841 13.3773 3.9333C13.004 3.3682 12.4848 2.91471 11.8745 2.62099C11.2643 2.32727 10.586 2.20428 9.91147 2.26507C9.23698 2.32587 8.59154 2.56819 8.04368 2.9663C7.49583 3.36441 7.066 3.90345 6.79985 4.52618L6.25985 5.79068L4.95335 5.36168C4.64419 5.26043 4.31674 5.22743 3.99358 5.26495C3.67042 5.30247 3.35925 5.40962 3.08151 5.57902C2.80376 5.74842 2.56606 5.97604 2.38477 6.24617C2.20349 6.51631 2.08295 6.82255 2.03145 7.14377C1.97996 7.465 1.99873 7.79357 2.08648 8.10684C2.17424 8.42011 2.32888 8.71062 2.53975 8.95835C2.75062 9.20608 3.01271 9.40514 3.30794 9.5418C3.60317 9.67845 3.92453 9.74946 4.24985 9.74993Z"
      fill={props.fill}
    />
  </svg>
);

export default Cloud;
