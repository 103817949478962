import React from "react";

const EyeCloseFilled = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye-close-f"
  >
    <path d="M9.44018 11.5708L3.43268 17.5766C3.35581 17.6562 3.26386 17.7197 3.16219 17.7633C3.06052 17.807 2.95117 17.83 2.84052 17.831C2.72987 17.8319 2.62013 17.8108 2.51772 17.7689C2.41531 17.727 2.32226 17.6652 2.24402 17.5869C2.16578 17.5087 2.1039 17.4156 2.062 17.3132C2.0201 17.2108 1.99901 17.1011 1.99997 16.9904C2.00094 16.8798 2.02392 16.7704 2.0676 16.6688C2.11127 16.5671 2.17476 16.4751 2.25435 16.3983L8.35768 10.2949C8.31017 10.0303 8.32736 9.75818 8.4078 9.50167C8.48823 9.24517 8.62952 9.01193 8.8196 8.82184C9.00969 8.63176 9.24293 8.49047 9.49943 8.41004C9.75594 8.3296 10.0281 8.31241 10.2927 8.35992L16.3985 2.25492C16.5557 2.10312 16.7662 2.01913 16.9847 2.02103C17.2032 2.02293 17.4122 2.11057 17.5667 2.26507C17.7212 2.41958 17.8088 2.62859 17.8107 2.84709C17.8126 3.06559 17.7286 3.27609 17.5768 3.43326L11.5702 9.44159C11.6763 9.73873 11.6958 10.0599 11.6266 10.3677C11.5573 10.6755 11.4022 10.9574 11.1791 11.1805C10.956 11.4036 10.6741 11.5587 10.3663 11.628C10.0585 11.6972 9.73733 11.6777 9.44018 11.5716V11.5708ZM15.7235 6.46492C17.291 7.36826 18.3327 8.61659 18.3327 9.99992C18.3327 12.7616 14.201 14.9941 9.99935 14.9999C9.14432 14.9999 8.29164 14.9105 7.45518 14.7333L9.00602 13.1833C9.58668 13.3647 10.2059 13.3838 10.7967 13.2384C11.3874 13.0931 11.9271 12.7888 12.3573 12.3587C12.7874 11.9285 13.0917 11.3888 13.237 10.7981C13.3824 10.2073 13.3633 9.58809 13.1818 9.00742L15.7235 6.46492ZM12.2568 5.21826L10.7285 6.74659C10.1802 6.6235 9.60963 6.64104 9.0699 6.79758C8.53016 6.95412 8.03876 7.24458 7.64139 7.64196C7.24401 8.03934 6.95355 8.53073 6.79701 9.07047C6.64046 9.61021 6.62292 10.1808 6.74602 10.7291L4.05685 13.4166C2.61102 12.5233 1.66602 11.3208 1.66602 9.99992C1.66602 7.23826 5.81935 4.98576 9.99935 4.99992C10.761 5.00242 11.5218 5.07826 12.2568 5.21826Z" />
  </svg>
);

export default EyeCloseFilled;
