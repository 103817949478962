import React from "react";

const MessagesAlt = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="messages-alt">
      <path
        id="Vector"
        d="M9.403 16.026L10.043 15.916C10.167 15.894 10.315 15.862 10.486 15.818C12.6 15.288 14 13.708 14 12C14 9.865 11.824 8 9 8C6.176 8 4 9.865 4 12C4 13.218 4.702 14.378 5.931 15.15L5.967 15.17L7 15.74V18.503L9.403 16.026ZM10.385 17.886L6.718 21.666C6.57942 21.809 6.40132 21.9073 6.20651 21.9484C6.0117 21.9895 5.80905 21.9715 5.62452 21.8968C5.43999 21.822 5.28197 21.6939 5.17071 21.5288C5.05945 21.3637 5.00001 21.1691 5 20.97V16.92C4.95533 16.8959 4.91131 16.8705 4.868 16.844C3.129 15.752 2 13.989 2 12C2 8.686 5.134 6 9 6C12.866 6 16 8.686 16 12C16 14.726 13.879 17.028 10.974 17.758C10.7793 17.8079 10.5828 17.8506 10.385 17.886ZM8.936 5C10.146 3.207 12.41 2 15 2C18.866 2 22 4.686 22 8C22 9.989 20.87 11.752 19.132 12.844C19.0887 12.8705 19.0447 12.8959 19 12.92V16.97C19 17.1691 18.9405 17.3637 18.8293 17.5288C18.718 17.6939 18.56 17.822 18.3755 17.8968C18.1909 17.9715 17.9883 17.9895 17.7935 17.9484C17.5987 17.9073 17.4206 17.809 17.282 17.666L15.547 15.878L16.59 14.08L17 14.503V11.74L18.033 11.17L18.069 11.15C19.299 10.378 20 9.218 20 8C20 5.865 17.824 4 15 4C13.72 4 12.574 4.383 11.703 5H8.936Z"
      />
    </g>
  </svg>
);

export default MessagesAlt;
