import { css } from "@emotion/core";

import { theme } from "@styles/theme";

export const scrollbar = css`
  scrollbar-width: thin;
  scrollbar-color: ${`${theme.colors.neutral["200"]} ${theme.colors.neutral["400"]}`};

  /* IE?? */
  scrollbar-face-color: ${theme.colors.neutral["200"]};
  scrollbar-shadow-color: ${theme.colors.neutral["400"]};
  scrollbar-highlight-color: ${theme.colors.neutral["400"]};

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    width: 8px;
    height: 8px;
    border-radius: 100px;
  }
  &::-webkit-scrollbar {
    background: ${theme.colors.neutral["200"]};
  }
  &::-webkit-scrollbar-thumb {
    background: ${theme.colors.neutral["400"]};
  }
`;
