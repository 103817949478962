import React from "react";

const Minus = (props: { height: number; width: number; fill?: string }) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.99967 7.3335H11.9997C12.1765 7.3335 12.3461 7.40373 12.4711 7.52876C12.5961 7.65378 12.6663 7.82335 12.6663 8.00016C12.6663 8.17697 12.5961 8.34654 12.4711 8.47157C12.3461 8.59659 12.1765 8.66683 11.9997 8.66683H3.99967C3.82286 8.66683 3.65329 8.59659 3.52827 8.47157C3.40325 8.34654 3.33301 8.17697 3.33301 8.00016C3.33301 7.82335 3.40325 7.65378 3.52827 7.52876C3.65329 7.40373 3.82286 7.3335 3.99967 7.3335Z" />
  </svg>
);

export default Minus;
