import React from "react";

const Update = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.05324 14.5996C7.20802 14.5996 6.41188 14.4386 5.66482 14.1167C4.91776 13.7947 4.26524 13.3568 3.70727 12.8031C3.1493 12.2493 2.70865 11.5998 2.38534 10.8545C2.06204 10.1092 1.90039 9.31329 1.90039 8.46666C1.90039 7.62004 2.06204 6.82964 2.38534 6.09546C2.70865 5.36128 3.1493 4.7201 3.70727 4.17191C4.26524 3.62372 4.91783 3.19075 5.66506 2.873C6.41229 2.55524 7.20861 2.39636 8.05401 2.39636C8.94555 2.39636 9.78987 2.58948 10.587 2.97571C11.3841 3.36195 12.0768 3.8913 12.6652 4.56376V2.84093H13.7091V6.38333H10.146V5.33151H11.8123C11.328 4.78806 10.7635 4.35292 10.1187 4.0261C9.47387 3.69927 8.78565 3.53586 8.05401 3.53586C6.6731 3.53586 5.49218 4.00731 4.51126 4.9502C3.53034 5.89307 3.03987 7.04832 3.03987 8.41595C3.03987 9.8169 3.52527 11.0085 4.49607 11.9908C5.46687 12.973 6.65285 13.4641 8.05401 13.4641C9.42961 13.4641 10.5917 12.9788 11.5403 12.008C12.4889 11.0373 12.9648 9.86239 12.9681 8.48333H14.1037C14.1037 10.1792 13.5166 11.6226 12.3424 12.8134C11.1683 14.0042 9.73854 14.5996 8.05324 14.5996ZM10.0634 11.2406L7.49676 8.70725V5.11666H8.54061V8.26666L10.816 10.488L10.0634 11.2406Z" />
  </svg>
);
export default Update;
