import React from "react";

const SortDescending = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye-close-f"
  >
    <path d="M3.60002 3.59998C2.93728 3.59998 2.40002 4.13723 2.40002 4.79998C2.40002 5.46272 2.93728 5.99998 3.60002 5.99998H16.8C17.4628 5.99998 18 5.46272 18 4.79998C18 4.13723 17.4628 3.59998 16.8 3.59998H3.60002Z" />
    <path d="M3.60002 8.39998C2.93728 8.39998 2.40002 8.93723 2.40002 9.59998C2.40002 10.2627 2.93728 10.8 3.60002 10.8H9.60002C10.2628 10.8 10.8 10.2627 10.8 9.59998C10.8 8.93723 10.2628 8.39998 9.60002 8.39998H3.60002Z" />
    <path d="M3.60002 13.2C2.93728 13.2 2.40002 13.7372 2.40002 14.4C2.40002 15.0627 2.93728 15.6 3.60002 15.6H8.40002C9.06277 15.6 9.60002 15.0627 9.60002 14.4C9.60002 13.7372 9.06277 13.2 8.40002 13.2H3.60002Z" />
    <path d="M15.6 19.2C15.6 19.8627 16.1373 20.4 16.8 20.4C17.4628 20.4 18 19.8627 18 19.2L18 12.497L19.5515 14.0485C20.0201 14.5171 20.7799 14.5171 21.2486 14.0485C21.7172 13.5799 21.7172 12.8201 21.2486 12.3514L17.6486 8.75145C17.4235 8.5264 17.1183 8.39997 16.8 8.39997C16.4818 8.39997 16.1765 8.5264 15.9515 8.75145L12.3515 12.3514C11.8829 12.8201 11.8829 13.5799 12.3515 14.0485C12.8201 14.5171 13.5799 14.5171 14.0486 14.0485L15.6 12.497L15.6 19.2Z" />
  </svg>
);

export default SortDescending;
