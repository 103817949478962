import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const Logo: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 -2 24 24"
    width={props.width}
    fill={props.fill}
    height={props.height}
    preserveAspectRatio="xMinYMin"
    className="jam jam-chevron-circle-left"
  >
    <path d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1.828-8l3.535 3.536a1 1 0 0 1-1.414 1.414L6.05 10.707a1 1 0 0 1 0-1.414l4.243-4.243a1 1 0 0 1 1.414 1.414L8.172 10z" />
  </svg>
);

export default Logo;
