import React from "react";

const Check = (props: { height: number; width: number; fill?: string }) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18 18"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.86492 12.5475C7.76647 12.5477 7.66895 12.5285 7.57795 12.4909C7.48696 12.4533 7.40428 12.3982 7.33467 12.3285L4.15317 9.14629C4.08149 9.07716 4.02429 8.99444 3.98492 8.90297C3.94555 8.81149 3.9248 8.71309 3.92386 8.61351C3.92293 8.51392 3.94183 8.41515 3.97948 8.32296C4.01712 8.23076 4.07275 8.14698 4.14312 8.07651C4.21349 8.00604 4.29719 7.95029 4.38934 7.91252C4.48148 7.87474 4.58023 7.8557 4.67981 7.85649C4.77939 7.85728 4.87782 7.8779 4.96935 7.91714C5.06088 7.95638 5.14368 8.01346 5.21292 8.08504L7.86417 10.7363L12.6379 5.96404C12.7785 5.82331 12.9693 5.74421 13.1683 5.74414C13.3672 5.74407 13.5581 5.82304 13.6988 5.96367C13.8395 6.1043 13.9186 6.29507 13.9187 6.49403C13.9188 6.69298 13.8398 6.88381 13.6992 7.02454L8.39517 12.3285C8.32555 12.3982 8.24288 12.4533 8.15188 12.4909C8.06089 12.5285 7.96337 12.5477 7.86492 12.5475Z" />
  </svg>
);

export default Check;
