import React from "react";

const Logout = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.41401 10.828H14.056C14.3212 10.828 14.5756 10.9334 14.7631 11.1209C14.9507 11.3084 15.056 11.5628 15.056 11.828C15.056 12.0932 14.9507 12.3476 14.7631 12.5351C14.5756 12.7226 14.3212 12.828 14.056 12.828H8.41401L9.53601 13.95C9.62886 14.0429 9.70249 14.1532 9.75271 14.2746C9.80294 14.3959 9.82876 14.526 9.82872 14.6574C9.82867 14.7887 9.80275 14.9188 9.75244 15.0401C9.70214 15.1614 9.62842 15.2717 9.53551 15.3645C9.4426 15.4573 9.33231 15.531 9.21095 15.5812C9.08958 15.6314 8.95951 15.6572 8.82816 15.6572C8.69681 15.6572 8.56676 15.6312 8.44543 15.5809C8.32409 15.5306 8.21386 15.4569 8.12101 15.364L5.29301 12.536C5.19968 12.4433 5.1256 12.3331 5.07505 12.2117C5.0245 12.0903 4.99847 11.96 4.99847 11.8285C4.99847 11.697 5.0245 11.5667 5.07505 11.4453C5.1256 11.3239 5.19968 11.2137 5.29301 11.121L8.12001 8.293C8.21286 8.20009 8.32309 8.12638 8.44442 8.07607C8.56576 8.02576 8.69581 7.99984 8.82716 7.9998C8.95851 7.99975 9.08858 8.02558 9.20995 8.0758C9.33131 8.12602 9.4416 8.19966 9.53451 8.2925C9.62742 8.38534 9.70114 8.49558 9.75144 8.61691C9.80175 8.73825 9.82767 8.8683 9.82771 8.99965C9.82776 9.13099 9.80194 9.26107 9.75171 9.38243C9.70149 9.5038 9.62786 9.61409 9.53501 9.707L8.41401 10.828ZM18 3C18.2652 3 18.5196 3.10536 18.7071 3.29289C18.8947 3.48043 19 3.73478 19 4V20C19 20.2652 18.8947 20.5196 18.7071 20.7071C18.5196 20.8946 18.2652 21 18 21C17.7348 21 17.4804 20.8946 17.2929 20.7071C17.1054 20.5196 17 20.2652 17 20V4C17 3.73478 17.1054 3.48043 17.2929 3.29289C17.4804 3.10536 17.7348 3 18 3Z" />
  </svg>
);

export default Logout;
