import React from "react";

const ChevronsDown = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 17"
    fill={props.fill}
    width={props.width}
    height={props.height}
    preserveAspectRatio="xMinYMin"
  >
    <path
      d="M8.54775 7.37657L11.8478 4.07657C11.9093 4.0129 11.9828 3.96211 12.0642 3.92717C12.1455 3.89223 12.233 3.87384 12.3215 3.87307C12.41 3.8723 12.4978 3.88917 12.5797 3.92269C12.6617 3.95621 12.7361 4.00571 12.7987 4.06831C12.8613 4.1309 12.9108 4.20534 12.9443 4.28727C12.9778 4.3692 12.9947 4.45699 12.9939 4.54551C12.9932 4.63403 12.9748 4.72151 12.9398 4.80284C12.9049 4.88418 12.8541 4.95774 12.7904 5.01924L9.01909 8.79057C8.89407 8.91555 8.72453 8.98576 8.54775 8.98576C8.37098 8.98576 8.20144 8.91555 8.07642 8.79057L4.30509 5.01924C4.24141 4.95774 4.19063 4.88418 4.15569 4.80284C4.12075 4.72151 4.10236 4.63403 4.10159 4.54551C4.10082 4.45699 4.11769 4.3692 4.15121 4.28727C4.18473 4.20534 4.23423 4.1309 4.29682 4.06831C4.35942 4.00571 4.43385 3.95621 4.51579 3.92269C4.59772 3.88917 4.6855 3.8723 4.77402 3.87307C4.86254 3.87384 4.95002 3.89223 5.03136 3.92717C5.11269 3.96211 5.18626 4.0129 5.24775 4.07657L8.54775 7.37657ZM8.54775 11.3766L11.8478 8.07657C11.9093 8.0129 11.9828 7.96211 12.0642 7.92717C12.1455 7.89223 12.233 7.87384 12.3215 7.87307C12.41 7.8723 12.4978 7.88917 12.5797 7.92269C12.6617 7.95621 12.7361 8.00571 12.7987 8.06831C12.8613 8.1309 12.9108 8.20534 12.9443 8.28727C12.9778 8.3692 12.9947 8.45699 12.9939 8.54551C12.9932 8.63403 12.9748 8.72151 12.9398 8.80284C12.9049 8.88418 12.8541 8.95774 12.7904 9.01924L9.01909 12.7906C8.89407 12.9156 8.72453 12.9858 8.54775 12.9858C8.37098 12.9858 8.20144 12.9156 8.07642 12.7906L4.30509 9.01924C4.24141 8.95774 4.19063 8.88418 4.15569 8.80284C4.12075 8.72151 4.10236 8.63403 4.10159 8.54551C4.10082 8.45699 4.11769 8.3692 4.15121 8.28727C4.18473 8.20534 4.23423 8.1309 4.29682 8.06831C4.35942 8.00571 4.43385 7.95621 4.51579 7.92269C4.59772 7.88917 4.6855 7.8723 4.77402 7.87307C4.86254 7.87384 4.95002 7.89223 5.03136 7.92717C5.11269 7.96211 5.18626 8.0129 5.24775 8.07657L8.54775 11.3766Z"
      fill="white"
    />
  </svg>
);

export default ChevronsDown;
