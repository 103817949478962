import React from "react";

const LowProbability = (props: {
  height: number;
  width: number;
  fill?: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 1.5C10.1046 1.5 11 2.39543 11 3.5V9.5C11 10.6046 10.1046 11.5 9 11.5H3C1.89543 11.5 1 10.6046 1 9.5V3.5C1 2.39543 1.89543 1.5 3 1.5H9ZM9 2.5H3C2.47282 2.5 2.04092 2.90794 2.00274 3.42537L2 3.5V9.5C2 10.0272 2.40794 10.4591 2.92537 10.4973L3 10.5H9C9.52718 10.5 9.95908 10.0921 9.99726 9.57463L10 9.5V3.5C10 2.97282 9.59206 2.54092 9.07463 2.50274L9 2.5ZM6 5.5C6.55228 5.5 7 5.94772 7 6.5C7 7.05228 6.55228 7.5 6 7.5C5.44772 7.5 5 7.05228 5 6.5C5 5.94772 5.44772 5.5 6 5.5Z"
      fill="#2C5282"
    />
  </svg>
);

export default LowProbability;
