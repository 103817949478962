import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 16 17"
    width={props.width}
    height={props.height}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.00065 15.1673C4.31865 15.1673 1.33398 12.1827 1.33398 8.50065C1.33398 4.81865 4.31865 1.83398 8.00065 1.83398C11.6827 1.83398 14.6673 4.81865 14.6673 8.50065C14.6673 12.1827 11.6827 15.1673 8.00065 15.1673ZM8.00065 13.834C9.41514 13.834 10.7717 13.2721 11.7719 12.2719C12.7721 11.2717 13.334 9.91514 13.334 8.50065C13.334 7.08616 12.7721 5.72961 11.7719 4.72941C10.7717 3.72922 9.41514 3.16732 8.00065 3.16732C6.58616 3.16732 5.22961 3.72922 4.22941 4.72941C3.22922 5.72961 2.66732 7.08616 2.66732 8.50065C2.66732 9.91514 3.22922 11.2717 4.22941 12.2719C5.22961 13.2721 6.58616 13.834 8.00065 13.834ZM8.00065 7.16732C8.17746 7.16732 8.34703 7.23756 8.47206 7.36258C8.59708 7.4876 8.66732 7.65717 8.66732 7.83398V11.1673C8.66732 11.3441 8.59708 11.5137 8.47206 11.6387C8.34703 11.7637 8.17746 11.834 8.00065 11.834C7.82384 11.834 7.65427 11.7637 7.52925 11.6387C7.40422 11.5137 7.33398 11.3441 7.33398 11.1673V7.83398C7.33398 7.65717 7.40422 7.4876 7.52925 7.36258C7.65427 7.23756 7.82384 7.16732 8.00065 7.16732ZM8.00065 6.50065C7.82384 6.50065 7.65427 6.43041 7.52925 6.30539C7.40422 6.18036 7.33398 6.0108 7.33398 5.83398C7.33398 5.65717 7.40422 5.4876 7.52925 5.36258C7.65427 5.23756 7.82384 5.16732 8.00065 5.16732C8.17746 5.16732 8.34703 5.23756 8.47206 5.36258C8.59708 5.4876 8.66732 5.65717 8.66732 5.83398C8.66732 6.0108 8.59708 6.18036 8.47206 6.30539C8.34703 6.43041 8.17746 6.50065 8.00065 6.50065Z" />
  </svg>
);

export default Logo;
