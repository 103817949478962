import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1 -1 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-key"
  >
    <path d="M8.612 16.337l3.746-3.747 1.027.183a5 5 0 1 0-4.039-4.039l.184 1.028-6.994 6.994.177 2.651 2.651.177 1.833-1.833-.707-.707a1 1 0 0 1 1.415-1.414l.707.707zm.707-13.435a7 7 0 1 1 3.715 11.84L6.137 21.64l-4.43-.295a1 1 0 0 1-.932-.932l-.295-4.43 6.898-6.898a6.992 6.992 0 0 1 1.94-6.183zm4.242 5.656A2 2 0 1 1 16.39 5.73a2 2 0 0 1-2.829 2.828z" />
  </svg>
);

export default Logo;
