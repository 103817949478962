import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const ArrowCircleMinus: React.FC<Props> = (props) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-2 -2 24 24"
  >
    <path d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0 2C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM5 9h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2z" />
  </svg>
);

export default ArrowCircleMinus;
