import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-key"
  >
    <path d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM12 17C11.7348 17 11.4804 16.8946 11.2929 16.7071C11.1054 16.5196 11 16.2652 11 16C11 15.7348 11.1054 15.4804 11.2929 15.2929C11.4804 15.1054 11.7348 15 12 15C12.2652 15 12.5196 15.1054 12.7071 15.2929C12.8946 15.4804 13 15.7348 13 16C13 16.2652 12.8946 16.5196 12.7071 16.7071C12.5196 16.8946 12.2652 17 12 17ZM13.276 13.782C13.067 13.9454 12.8017 14.019 12.5384 13.9868C12.2751 13.9545 12.0354 13.819 11.872 13.61C11.7086 13.401 11.635 13.1357 11.6672 12.8724C11.6995 12.6091 11.835 12.3694 12.044 12.206L12.438 11.898C12.5939 11.777 12.7245 11.6264 12.8221 11.4548C12.9197 11.2832 12.9825 11.0941 13.0069 10.8982C13.0312 10.7023 13.0167 10.5036 12.9641 10.3133C12.9115 10.123 12.8219 9.94504 12.7003 9.78949C12.5788 9.63395 12.4278 9.50392 12.2559 9.40686C12.084 9.3098 11.8947 9.24762 11.6987 9.22388C11.5027 9.20015 11.304 9.21532 11.1139 9.26854C10.9239 9.32176 10.7462 9.41197 10.591 9.534L10.197 9.842C10.094 9.92729 9.97489 9.99106 9.84679 10.0295C9.71869 10.068 9.58418 10.0803 9.45121 10.0659C9.31825 10.0514 9.18953 10.0104 9.07269 9.94537C8.95584 9.88029 8.85324 9.79243 8.77095 9.68699C8.68866 9.58155 8.62836 9.46068 8.59361 9.33152C8.55886 9.20237 8.55038 9.06755 8.56866 8.93506C8.58694 8.80257 8.63161 8.67509 8.70003 8.56017C8.76845 8.44524 8.85923 8.34522 8.967 8.266L9.36 7.958C10.0915 7.38646 11.02 7.1289 11.9414 7.24198C12.8628 7.35507 13.7015 7.82953 14.273 8.561C14.8445 9.29247 15.1021 10.221 14.989 11.1424C14.8759 12.0638 14.4015 12.9025 13.67 13.474L13.276 13.782Z" />
  </svg>
);

export default Logo;
