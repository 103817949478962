import React from "react";

const CircleInfo = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.99992 14.6666C4.31792 14.6666 1.33325 11.6819 1.33325 7.99992C1.33325 4.31792 4.31792 1.33325 7.99992 1.33325C11.6819 1.33325 14.6666 4.31792 14.6666 7.99992C14.6666 11.6819 11.6819 14.6666 7.99992 14.6666ZM7.99992 13.3333C9.41441 13.3333 10.771 12.7713 11.7712 11.7712C12.7713 10.771 13.3333 9.41441 13.3333 7.99992C13.3333 6.58543 12.7713 5.22888 11.7712 4.22868C10.771 3.22849 9.41441 2.66659 7.99992 2.66659C6.58543 2.66659 5.22888 3.22849 4.22868 4.22868C3.22849 5.22888 2.66659 6.58543 2.66659 7.99992C2.66659 9.41441 3.22849 10.771 4.22868 11.7712C5.22888 12.7713 6.58543 13.3333 7.99992 13.3333V13.3333ZM7.99992 6.66659C8.17673 6.66659 8.3463 6.73682 8.47132 6.86185C8.59635 6.98687 8.66659 7.15644 8.66659 7.33325V10.6666C8.66659 10.8434 8.59635 11.013 8.47132 11.138C8.3463 11.263 8.17673 11.3333 7.99992 11.3333C7.82311 11.3333 7.65354 11.263 7.52851 11.138C7.40349 11.013 7.33325 10.8434 7.33325 10.6666V7.33325C7.33325 7.15644 7.40349 6.98687 7.52851 6.86185C7.65354 6.73682 7.82311 6.66659 7.99992 6.66659V6.66659ZM7.99992 5.99992C7.82311 5.99992 7.65354 5.92968 7.52851 5.80466C7.40349 5.67963 7.33325 5.51006 7.33325 5.33325C7.33325 5.15644 7.40349 4.98687 7.52851 4.86185C7.65354 4.73682 7.82311 4.66659 7.99992 4.66659C8.17673 4.66659 8.3463 4.73682 8.47132 4.86185C8.59635 4.98687 8.66659 5.15644 8.66659 5.33325C8.66659 5.51006 8.59635 5.67963 8.47132 5.80466C8.3463 5.92968 8.17673 5.99992 7.99992 5.99992Z" />
  </svg>
);

export default CircleInfo;
