import React from "react";

const SortDescending = (props: {
  height: number;
  width: number;
  fill: string;
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye-close-f"
  >
    <path d="M3.60002 3.59998C2.93728 3.59998 2.40002 4.13723 2.40002 4.79998C2.40002 5.46272 2.93728 5.99998 3.60002 5.99998H16.8C17.4628 5.99998 18 5.46272 18 4.79998C18 4.13723 17.4628 3.59998 16.8 3.59998H3.60002Z" />
    <path d="M3.60002 8.39998C2.93728 8.39998 2.40002 8.93724 2.40002 9.59998C2.40002 10.2627 2.93728 10.8 3.60002 10.8H12C12.6628 10.8 13.2 10.2627 13.2 9.59998C13.2 8.93724 12.6628 8.39998 12 8.39998H3.60002Z" />
    <path d="M3.60002 13.2C2.93728 13.2 2.40002 13.7372 2.40002 14.4C2.40002 15.0627 2.93728 15.6 3.60002 15.6H8.40002C9.06277 15.6 9.60002 15.0627 9.60002 14.4C9.60002 13.7372 9.06277 13.2 8.40002 13.2H3.60002Z" />
    <path d="M18 9.59998C18 8.93724 17.4628 8.39998 16.8 8.39998C16.1373 8.39998 15.6 8.93724 15.6 9.59998L15.6 16.3029L14.0486 14.7515C13.5799 14.2828 12.8201 14.2828 12.3515 14.7515C11.8829 15.2201 11.8829 15.9799 12.3515 16.4485L15.9515 20.0485C16.1765 20.2736 16.4818 20.4 16.8 20.4C17.1183 20.4 17.4235 20.2736 17.6486 20.0485L21.2486 16.4485C21.7172 15.9799 21.7172 15.2201 21.2486 14.7515C20.7799 14.2828 20.0201 14.2828 19.5515 14.7515L18 16.3029L18 9.59998Z" />
  </svg>
);

export default SortDescending;
