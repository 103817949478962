import React from "react";

const Star = (props: { height: number; width: number; fill: string }) => (
  <svg
    viewBox="0 0 20 20"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-star"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={props.fill}
      d="M9.9998 13.3783L12.9306 14.92L12.3706 11.6558L14.7423 9.34413L11.4656 8.8683L9.9998 5.89913L8.53397 8.8683L5.2573 9.34413L7.62897 11.6558L7.06897 14.9191L9.9998 13.3783ZM9.9998 15.2616L4.85564 17.9666L5.83814 12.2375L1.6748 8.1808L7.42647 7.34497L9.9998 2.1333L12.5723 7.34497L18.324 8.1808L14.1615 12.2375L15.1448 17.9666L9.9998 15.2616Z"
    />
  </svg>
);

export default Star;
