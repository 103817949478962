import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const BarChart: React.FC<Props> = (props) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 16 16"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.99998 2.66699C4.17679 2.66699 4.34636 2.73723 4.47138 2.86225C4.59641 2.98728 4.66665 3.15685 4.66665 3.33366V12.667C4.66665 12.8438 4.59641 13.0134 4.47138 13.1384C4.34636 13.2634 4.17679 13.3337 3.99998 13.3337C3.82317 13.3337 3.6536 13.2634 3.52858 13.1384C3.40355 13.0134 3.33331 12.8438 3.33331 12.667V3.33366C3.33331 3.15685 3.40355 2.98728 3.52858 2.86225C3.6536 2.73723 3.82317 2.66699 3.99998 2.66699ZM12 5.33366C12.1768 5.33366 12.3464 5.4039 12.4714 5.52892C12.5964 5.65395 12.6666 5.82351 12.6666 6.00033V12.667C12.6666 12.8438 12.5964 13.0134 12.4714 13.1384C12.3464 13.2634 12.1768 13.3337 12 13.3337C11.8232 13.3337 11.6536 13.2634 11.5286 13.1384C11.4036 13.0134 11.3333 12.8438 11.3333 12.667V6.00033C11.3333 5.82351 11.4036 5.65395 11.5286 5.52892C11.6536 5.4039 11.8232 5.33366 12 5.33366ZM7.99998 8.00033C8.17679 8.00033 8.34636 8.07056 8.47138 8.19559C8.59641 8.32061 8.66665 8.49018 8.66665 8.66699V12.667C8.66665 12.8438 8.59641 13.0134 8.47138 13.1384C8.34636 13.2634 8.17679 13.3337 7.99998 13.3337C7.82317 13.3337 7.6536 13.2634 7.52858 13.1384C7.40355 13.0134 7.33331 12.8438 7.33331 12.667V8.66699C7.33331 8.49018 7.40355 8.32061 7.52858 8.19559C7.6536 8.07056 7.82317 8.00033 7.99998 8.00033Z" />
  </svg>
);

export default BarChart;
