import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.65691 6.44997L8.42441 4.68197C8.47217 4.63585 8.51026 4.58068 8.53646 4.51967C8.56266 4.45867 8.57646 4.39306 8.57704 4.32667C8.57761 4.26028 8.56496 4.19444 8.53982 4.13299C8.51468 4.07155 8.47755 4.01572 8.43061 3.96877C8.38366 3.92183 8.32783 3.8847 8.26639 3.85956C8.20494 3.83442 8.1391 3.82177 8.07271 3.82235C8.00632 3.82292 7.94071 3.83672 7.87971 3.86292C7.81871 3.88912 7.76353 3.92722 7.71741 3.97497L5.94941 5.74247L4.18191 3.97497C4.13579 3.92722 4.08061 3.88912 4.01961 3.86292C3.95861 3.83672 3.893 3.82292 3.82661 3.82235C3.76022 3.82177 3.69438 3.83442 3.63293 3.85956C3.57148 3.8847 3.51566 3.92183 3.46871 3.96877C3.42177 4.01572 3.38464 4.07155 3.3595 4.13299C3.33436 4.19444 3.32171 4.26028 3.32228 4.32667C3.32286 4.39306 3.33665 4.45867 3.36286 4.51967C3.38906 4.58068 3.42715 4.63585 3.47491 4.68197L5.24241 6.44947L3.47491 8.21747C3.42715 8.26359 3.38906 8.31877 3.36286 8.37977C3.33665 8.44077 3.32286 8.50638 3.32228 8.57277C3.32171 8.63916 3.33436 8.705 3.3595 8.76645C3.38464 8.8279 3.42177 8.88372 3.46871 8.93067C3.51566 8.97761 3.57148 9.01474 3.63293 9.03988C3.69438 9.06502 3.76022 9.07767 3.82661 9.0771C3.893 9.07652 3.95861 9.06273 4.01961 9.03652C4.08061 9.01032 4.13579 8.97223 4.18191 8.92447L5.94941 7.15697L7.71741 8.92447C7.76353 8.97223 7.81871 9.01032 7.87971 9.03652C7.94071 9.06273 8.00632 9.07652 8.07271 9.0771C8.1391 9.07767 8.20494 9.06502 8.26639 9.03988C8.32783 9.01474 8.38366 8.97761 8.43061 8.93067C8.47755 8.88372 8.51468 8.8279 8.53982 8.76645C8.56496 8.705 8.57761 8.63916 8.57704 8.57277C8.57646 8.50638 8.56266 8.44077 8.53646 8.37977C8.51026 8.31877 8.47217 8.26359 8.42441 8.21747L6.65691 6.44947V6.44997Z" />
  </svg>
);

export default Logo;
