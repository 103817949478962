import React from "react";

const Leaf = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.53603 4.786C3.34955 5.05809 3.21877 5.36439 3.15121 5.68725C3.08366 6.01012 3.08066 6.34316 3.1424 6.66718C3.20413 6.99121 3.32938 7.29982 3.51093 7.57522C3.69247 7.85062 3.92672 8.08737 4.20018 8.27183C4.47364 8.4563 4.7809 8.58482 5.10425 8.64999C5.4276 8.71517 5.76066 8.71571 6.08422 8.65158C6.40778 8.58746 6.71546 8.45994 6.98951 8.27637C7.26357 8.0928 7.49859 7.85681 7.68103 7.582C8.18353 6.8375 8.55003 5.174 8.72353 2.657C5.75203 3.35 3.99203 4.111 3.53603 4.786ZM3.65153 9.086C3.27045 8.82898 2.94373 8.49942 2.69002 8.11613C2.43632 7.73284 2.26059 7.30333 2.17289 6.85212C2.08518 6.40092 2.08721 5.93685 2.17886 5.48643C2.27051 5.03601 2.44998 4.60805 2.70703 4.227C3.42803 3.1585 5.79003 2.2165 9.79203 1.4005C9.65803 4.826 9.23103 7.073 8.51053 8.1415C8.25351 8.52258 7.92395 8.8493 7.54066 9.103C7.15736 9.35671 6.72785 9.53243 6.27665 9.62014C5.82544 9.70784 5.36138 9.70581 4.91096 9.61417C4.46054 9.52252 4.03258 9.34305 3.65153 9.086Z" />
    <path d="M2.78302 11.035C2.78302 11.1676 2.73034 11.2948 2.63657 11.3885C2.54281 11.4823 2.41563 11.535 2.28302 11.535C2.15041 11.535 2.02323 11.4823 1.92947 11.3885C1.8357 11.2948 1.78302 11.1676 1.78302 11.035C1.78302 9.3025 2.63302 7.6795 4.05802 6.6925L4.31152 6.5165C4.42052 6.44098 4.55506 6.41185 4.68554 6.43553C4.81602 6.4592 4.93175 6.53374 5.00727 6.64275C5.08279 6.75175 5.11192 6.88629 5.08824 7.01677C5.06456 7.14725 4.99002 7.26298 4.88102 7.3385L4.62752 7.5145C4.05844 7.90866 3.59337 8.43488 3.27213 9.04809C2.9509 9.66131 2.78307 10.3427 2.78302 11.035Z" />
  </svg>
);

export default Leaf;
