import React from "react";

const LowSeverity = (props: {
  height: number;
  width: number;
  fill?: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03549 5.164L3.56049 7.639C3.46619 7.73008 3.33989 7.78048 3.20879 7.77934C3.07769 7.7782 2.95229 7.72561 2.85958 7.63291C2.76688 7.54021 2.71429 7.4148 2.71315 7.2837C2.71201 7.1526 2.76241 7.0263 2.85349 6.932L5.68199 4.1035C5.77575 4.00977 5.90291 3.95711 6.03549 3.95711C6.16807 3.95711 6.29523 4.00977 6.38899 4.1035L9.21749 6.932C9.30857 7.0263 9.35897 7.1526 9.35783 7.2837C9.35669 7.4148 9.3041 7.54021 9.2114 7.63291C9.1187 7.72561 8.99329 7.7782 8.86219 7.77934C8.73109 7.78048 8.60479 7.73008 8.51049 7.639L6.03549 5.164Z"
      fill="#2C5282"
    />
  </svg>
);

export default LowSeverity;
