import React from "react";

const CopyCheck = (props: { height: number; width: number; fill: string }) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 11.3335H3.33333C2.97971 11.3335 2.64057 11.193 2.39052 10.943C2.14048 10.6929 2 10.3538 2 10.0002V2.66683C2 2.31321 2.14048 1.97407 2.39052 1.72402C2.64057 1.47397 2.97971 1.3335 3.33333 1.3335H8.66667C9.02029 1.3335 9.35943 1.47397 9.60948 1.72402C9.85952 1.97407 10 2.31321 10 2.66683V4.66683H12L14 6.66683V13.3335C14 13.6871 13.8595 14.0263 13.6095 14.2763C13.3594 14.5264 13.0203 14.6668 12.6667 14.6668H7.33333C6.97971 14.6668 6.64057 14.5264 6.39052 14.2763C6.14048 14.0263 6 13.6871 6 13.3335V11.3335ZM6 10.0002V6.00016C6 5.64654 6.14048 5.3074 6.39052 5.05735C6.64057 4.80731 6.97971 4.66683 7.33333 4.66683H8.66667V2.66683H3.33333V10.0002H6ZM11.448 6.00016H7.33333V13.3335H12.6667V7.21883L11.448 6.00016Z" />
  </svg>
);

export default CopyCheck;
