import React from "react";

const Bug = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
    className="jam jam-eye"
  >
    <path d="M29.2667 17.3333C28.5987 24.0706 22.9133 29.3333 16 29.3333C8.636 29.3333 2.66667 23.3639 2.66667 15.9999C2.66667 9.0866 7.92933 3.40127 14.6667 2.73327V5.41593C11.977 5.75818 9.51847 7.11176 7.79092 9.20156C6.06336 11.2914 5.19637 13.9606 5.36616 16.6666C5.53595 19.3727 6.72978 21.9126 8.70499 23.7701C10.6802 25.6276 13.2886 26.6632 16 26.6666C18.5981 26.6666 21.107 25.7184 23.0557 23.9999C25.0044 22.2815 26.2589 19.911 26.584 17.3333H29.268H29.2667ZM29.2667 14.6666H26.584C26.2872 12.3167 25.2172 10.1324 23.5424 8.45758C21.8676 6.78278 19.6832 5.71271 17.3333 5.41593V2.73193C20.3935 3.04025 23.2531 4.39674 25.4278 6.5717C27.6025 8.74665 28.9587 11.6064 29.2667 14.6666Z" />
  </svg>
);

export default Bug;
