import React from "react";

interface Props {
  fill: string;
  width: number | string;
  height: number | string;
}

const ArrowCircleLeft: React.FC<Props> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={props.width}
    height={props.height}
    fill={props.fill}
    preserveAspectRatio="xMinYMin"
  >
    <path
      d="M7.99992 14.6668C4.31792 14.6668 1.33325 11.6822 1.33325 8.00016C1.33325 4.31816 4.31792 1.3335 7.99992 1.3335C11.6819 1.3335 14.6666 4.31816 14.6666 8.00016C14.6666 11.6822 11.6819 14.6668 7.99992 14.6668ZM7.99992 13.3335C9.41441 13.3335 10.771 12.7716 11.7712 11.7714C12.7713 10.7712 13.3333 9.41465 13.3333 8.00016C13.3333 6.58567 12.7713 5.22912 11.7712 4.22893C10.771 3.22873 9.41441 2.66683 7.99992 2.66683C6.58543 2.66683 5.22888 3.22873 4.22868 4.22893C3.22849 5.22912 2.66659 6.58567 2.66659 8.00016C2.66659 9.41465 3.22849 10.7712 4.22868 11.7714C5.22888 12.7716 6.58543 13.3335 7.99992 13.3335ZM6.94258 7.3335H10.6666C10.8434 7.3335 11.013 7.40373 11.138 7.52876C11.263 7.65378 11.3333 7.82335 11.3333 8.00016C11.3333 8.17697 11.263 8.34654 11.138 8.47157C11.013 8.59659 10.8434 8.66683 10.6666 8.66683H6.94258L8.63325 10.3575C8.75469 10.4832 8.82189 10.6516 8.82037 10.8264C8.81885 11.0012 8.74874 11.1684 8.62513 11.292C8.50153 11.4156 8.33432 11.4858 8.15952 11.4873C7.98472 11.4888 7.81632 11.4216 7.69059 11.3002L4.86192 8.4715C4.7998 8.40973 4.7505 8.33629 4.71685 8.2554C4.68321 8.17451 4.6659 8.08777 4.6659 8.00016C4.6659 7.91256 4.68321 7.82582 4.71685 7.74493C4.7505 7.66404 4.7998 7.5906 4.86192 7.52883L7.69059 4.70016C7.81632 4.57872 7.98472 4.51153 8.15952 4.51305C8.33432 4.51457 8.50153 4.58468 8.62513 4.70828C8.74874 4.83189 8.81885 4.9991 8.82037 5.1739C8.82189 5.34869 8.75469 5.51709 8.63325 5.64283L6.94258 7.3335Z"
      fill={props.fill}
    />
  </svg>
);

export default ArrowCircleLeft;
