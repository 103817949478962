import React from "react";

const Archive = (props: {
  height: number | string;
  width: number | string;
  fill: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    viewBox="0 0 18 19"
    fill={props.fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="archive 1">
      <path
        id="Vector"
        d="M3 5.25H15V3H3V5.25ZM15 6.75V15.75C15 15.9489 14.921 16.1397 14.7803 16.2803C14.6397 16.421 14.4489 16.5 14.25 16.5H3.75C3.55109 16.5 3.36032 16.421 3.21967 16.2803C3.07902 16.1397 3 15.9489 3 15.75V6.75H2.25C2.05109 6.75 1.86032 6.67098 1.71967 6.53033C1.57902 6.38968 1.5 6.19891 1.5 6V2.25C1.5 2.05109 1.57902 1.86032 1.71967 1.71967C1.86032 1.57902 2.05109 1.5 2.25 1.5H15.75C15.9489 1.5 16.1397 1.57902 16.2803 1.71967C16.421 1.86032 16.5 2.05109 16.5 2.25V6C16.5 6.19891 16.421 6.38968 16.2803 6.53033C16.1397 6.67098 15.9489 6.75 15.75 6.75H15ZM4.5 15H13.5V6.75H4.5V15ZM7.5 12H10.5C10.6989 12 10.8897 12.079 11.0303 12.2197C11.171 12.3603 11.25 12.5511 11.25 12.75C11.25 12.9489 11.171 13.1397 11.0303 13.2803C10.8897 13.421 10.6989 13.5 10.5 13.5H7.5C7.30109 13.5 7.11032 13.421 6.96967 13.2803C6.82902 13.1397 6.75 12.9489 6.75 12.75C6.75 12.5511 6.82902 12.3603 6.96967 12.2197C7.11032 12.079 7.30109 12 7.5 12Z"
        fill="#E2E8F0"
      />
    </g>
  </svg>
);

export default Archive;
