import React from "react";

const Logo = (props: { height: number; width: number; fill: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 10 10"
    fill={props.fill}
    width={props.width}
    height={props.height}
    preserveAspectRatio="xMinYMin"
  >
    <path d="M5.0296 5.54761L7.0921 3.48511C7.13054 3.44532 7.17652 3.41358 7.22735 3.39174C7.27819 3.3699 7.33286 3.35841 7.38818 3.35793C7.44351 3.35745 7.49838 3.36799 7.54958 3.38894C7.60079 3.40989 7.64731 3.44083 7.68643 3.47995C7.72555 3.51907 7.75649 3.56559 7.77744 3.6168C7.79839 3.66801 7.80894 3.72287 7.80846 3.7782C7.80797 3.83352 7.79648 3.8882 7.77464 3.93903C7.75281 3.98987 7.72106 4.03584 7.68127 4.07428L5.32419 6.43136C5.24605 6.50948 5.14009 6.55336 5.0296 6.55336C4.91912 6.55336 4.81316 6.50948 4.73502 6.43136L2.37794 4.07428C2.33814 4.03584 2.3064 3.98987 2.28456 3.93903C2.26272 3.8882 2.25123 3.83352 2.25075 3.7782C2.25027 3.72287 2.26081 3.66801 2.28176 3.6168C2.30271 3.56559 2.33365 3.51907 2.37277 3.47995C2.41189 3.44083 2.45841 3.40989 2.50962 3.38894C2.56083 3.36799 2.61569 3.35745 2.67102 3.35793C2.72634 3.35841 2.78102 3.3699 2.83185 3.39174C2.88269 3.41358 2.92867 3.44532 2.9671 3.48511L5.0296 5.54761Z" />
  </svg>
);

export default Logo;
