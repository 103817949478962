import React from "react";

const MediumSeverity = (props: {
  height: number;
  width: number;
  fill?: string;
}) => (
  <svg
    width={props.width}
    height={props.height}
    fill={props.fill}
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.03551 3.664L3.56051 6.139C3.46621 6.23008 3.33991 6.28048 3.20881 6.27934C3.07771 6.2782 2.95231 6.22561 2.8596 6.13291C2.7669 6.04021 2.71431 5.9148 2.71317 5.7837C2.71203 5.6526 2.76243 5.5263 2.85351 5.432L5.68201 2.6035C5.77577 2.50977 5.90293 2.45711 6.03551 2.45711C6.16809 2.45711 6.29525 2.50977 6.38901 2.6035L9.21751 5.432C9.30859 5.5263 9.35899 5.6526 9.35785 5.7837C9.35671 5.9148 9.30412 6.04021 9.21142 6.13291C9.11872 6.22561 8.99331 6.2782 8.86221 6.27934C8.73111 6.28048 8.60481 6.23008 8.51051 6.139L6.03551 3.664ZM6.03551 6.664L3.56051 9.139C3.51439 9.18676 3.45921 9.22485 3.39821 9.25105C3.33721 9.27726 3.2716 9.29105 3.20521 9.29163C3.13882 9.2922 3.07298 9.27955 3.01153 9.25441C2.95009 9.22927 2.89426 9.19215 2.84731 9.1452C2.80037 9.09825 2.76324 9.04243 2.7381 8.98098C2.71296 8.91953 2.70031 8.85369 2.70089 8.7873C2.70146 8.72091 2.71526 8.6553 2.74146 8.5943C2.76766 8.5333 2.80576 8.47813 2.85351 8.432L5.68201 5.6035C5.77577 5.50977 5.90293 5.45711 6.03551 5.45711C6.16809 5.45711 6.29525 5.50977 6.38901 5.6035L9.21751 8.432C9.30859 8.5263 9.35899 8.6526 9.35785 8.7837C9.35671 8.9148 9.30412 9.04021 9.21142 9.13291C9.11872 9.22562 8.99331 9.2782 8.86221 9.27934C8.73111 9.28048 8.60481 9.23008 8.51051 9.139L6.03551 6.664Z"
      fill="#975A16"
    />
  </svg>
);

export default MediumSeverity;
